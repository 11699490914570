import { Component, EventEmitter, Input, NgZone, OnInit, ViewChild, ViewEncapsulation, Output } from "@angular/core";

import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { JsonPipe, LocationStrategy } from "@angular/common";
import { VideoPlayerService } from "src/app/core/services/videoPlayerService";
import { ApiService, DeviceService, UserService } from "src/app/core";
import { EventTrackingService } from "src/app/services/google/event-tracking.service";
import { jwPlayerService } from "src/app/views/jw-video-player/jwplayer.servies";
import { Analyticsstreaminglog, CatlougeTypeConstants } from "src/app/core/constants/index";
import { SpinnerVisibilityService } from "ng-http-loader";
import { ToastrService } from "ngx-toastr";
import { DfpVideoDirective } from "ngx-dfp/video";
import { Router } from "@angular/router";
import { isArray } from "rxjs/internal-compatibility";
import { stringify } from "querystring";
// import { setInterval } from "timers";
import * as CryptoJS from 'crypto-js';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from "@angular/common/http";
import { environment } from '../../../environments/environment';

declare var videojs;
declare var google;
declare var $: any;
declare var window: any;
declare function invokeSpriteScript(url, interval, width, height): any;
@Component({
  selector: "app-videojs-player",
  templateUrl: "./videojs-player.component.html",
  styleUrls: ["./videojs-player.component.css"],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbActiveModal],
})
export class VideojsPlayerComponent implements OnInit {
  @ViewChild(DfpVideoDirective) dfpVideo: DfpVideoDirective;

  adInput = new EventEmitter<any>();

  @Output() onModalClose = new EventEmitter<any>();

  @Input() streamInfo: any;
  playermodal: any;
  totalDuration: any;
  seekTime: any;
  userInfo: any;
  sessionId: any;
  title: string = "";
  title1: string = "";
  videoPlayUrl: any = "";

  userUnique: string = "NA";
  timerOfPlayer: any;
  timerForPlayer: number;
  videoType: string = "video";
  nextVideoCode: string = "";
  preVideoCode: string = "";
  nextVideoName: string = "";
  preVideoName: string = "";
  nextEpisodeNumber: string = "";
  preEpisodeNumber: string = "";
  nextSeasonNumber: string = "";
  preSeasonNumber: string = "";
  prePlayingThumb: string = "";

  preVideoDuration: string = "";
  is_trailer: number = 0;
  public is_live_evnet: number = 0;

  currentPlayingCode: string = "";
  currentPlayingCharegCode: string = "";
  currentPlayingMongoId: string = "";
  currentPlayingContentAvailability: string = "";
  currentPlayingThumb: string = "";
  currentPlayingSec: string = "";
  currentPlayingMin: string = "";
  currentPlayingHour: string = "";
  currentPlayingDuration: string = "";
  currentPlayingGenreText: string = "";
  currentPlayingGenre: string = "";
  currentPlayingLanguages: string = "";
  currentPlayingSys: string = "";
  currentPlayingShowID: string = "";
  currentPlayingSeasonId: string = "";
  currentPlayingName: string = "";
  currentLastPlayingSeasonId: string = "";

  nextPlayingMongoId = "";
  nextPlayingCharegCode: string = "";
  nextPlayingContentAvailability: string = "";
  nextPlayingThumb: string = "";
  nextPlayingSec: string = "";
  nextPlayingMin: string = "";
  nextPlayingHour: string = "";
  nextPlayingGenreText: string = "";
  nextPlayingGenre: string = "";
  nextPlayingLanguages: string = "";
  nextPlayingSys: string = "";
  nextPlayingStop: string = "";
  nextPlayingDuration: string = "";
  nextVideoDuration: string = "";
  nextPlayingStartTime: any = 0;
  prePlayingStartTime: any = 0;

  nextPlayingShowID: string = "";
  nextPlayingSeasonId: string = "";
  public action1: any;

  trickPlayUrl: string = "";
  trickPlaySize: string = "";
  // logic complication  ( nextPlayingStreamDetails & prePlayingStreamDetails variable hold the current object )
  nextPlayingStreamDetails = {
    charge_code: "",
    asset_mongo_id: "",
    release_date: "",
    name: "",
    thumbnail: "",
    content_availability: "",
    sec: "",
    min: "",
    hours: "",
    genre_name: "",
    languages: "",
    // genre: "",
    synopsis: "",
    season_id: "",
    show_id: "",
  };
  prePlayingStreamDetails = {
    charge_code: "",
    asset_mongo_id: "",
    release_date: "",
    name: "",
    thumbnail: "",
    content_availability: "",
    sec: "",
    min: "",
    hours: "",
    genre_name: "",
    languages: "",
    genre: "",
    synopsis: "",
    season_id: "",
    show_id: "",
  };

  playType: string = "";
  assetsCode: any;
  rmContApiCalledStatus = false;
  lastEpisode: any;

  error: string = "";
  startTime: any = 0;
  nextVideObj: any = { start: 0, stop: 0, duration: 0 };

  seasonarray: any = [];
  isHudredPersent: boolean = true;

  @ViewChild("showErrorMsg") showErrorMsg;
  @ViewChild("closeShowErrorMsg") closeShowErrorMsg;
  playUrlResObj: any;
  videoLabelObj: any;
  watch_credit_status: any = 1;
  currentPlayingTime: any;
  shouldCloseAfterPersent: boolean = true;

  currentPlayingData: any;
  interval_ID: any;

  data = "Example1";//Message to Encrypt
  iv = CryptoJS.enc.Base64.parse("");//giving empty initialization vector
  key = CryptoJS.SHA256("Message");//hashing the key using SHA256
  encrypted_UUID: any;

  deviceInfo = null;
  errmsg: any;

  ipAddress: any;
  nextVideoPaidData: any;
  nextEpisodeIsBlank: boolean = true;


  constructor(
    public activeModal: NgbActiveModal,
    private location: LocationStrategy,
    public VideoPlayerService: VideoPlayerService,
    private googleEvent: EventTrackingService,
    public UserService: UserService,
    public jwPlayerService: jwPlayerService,
    public spinner: SpinnerVisibilityService,
    private toastr: ToastrService,
    public router: Router,
    public apiService: ApiService,
    private userService: UserService,
    private deviceApiService: DeviceService,
    private http: HttpClient,
    private deviceService: DeviceDetectorService
  ) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      this.mobileBrowserBackbutton();
    });
    window.stop();
    document.addEventListener('contextmenu', event => event.preventDefault());
  }
  adEvent(event) {
    if (event.type === "complete") {
      // hide ad container
      this.dfpVideo.adContainer.style.zIndex = "-1";
      this.playermodal.play();
    }
    if (event.type === "start") {
      // show ad container
      // this.dfpVideo.adContainer.style.zIndex = '1';
    }
  }
  mobileBrowserBackbutton() {
    this.stopVideo();

    this.disableModal();
    if (this.playermodal) this.playermodal.dispose();

    this.playermodal = "";
    return false;
  }

  ngOnInit(): void {


    this.watch_credit_status = 1;
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
    this.sessionId = localStorage.getItem("sessionId");

    if (Object.keys(this.userInfo.result).length > 0) {
      if (this.userInfo.result.register_by == "mobile") {
        this.userUnique = this.userInfo.result.mobile;
      } else {
        this.userUnique = this.userInfo.result.emailaddress;
      }
    }

    this.streamInfo = this.streamInfo;
    this.videoType = this.streamInfo.type;
    console.log(this.streamInfo);

    if (
      this.streamInfo.recently_watched.asset != undefined &&
      this.videoType == "episode"
    ) {
      this.streamInfo.recently_watched.charge_code =
        this.streamInfo.recently_watched.asset.charge_code;
    }
    if (
      this.streamInfo.recently_watched.sec == undefined &&
      this.videoType == "episode"
    ) {
      if (this.streamInfo.recently_watched.asset != undefined)
        this.streamInfo.recently_watched.sec =
          this.streamInfo.recently_watched.asset.sec;
    }

    if (
      this.streamInfo.recently_watched.min == undefined &&
      this.videoType == "episode"
    ) {
      if (this.streamInfo.recently_watched.asset != undefined)
        this.streamInfo.recently_watched.min =
          this.streamInfo.recently_watched.asset.min;
    }

    if (
      this.streamInfo.recently_watched.hours == undefined &&
      this.videoType == "episode"
    ) {
      if (this.streamInfo.recently_watched.asset != undefined)
        this.streamInfo.recently_watched.hours =
          this.streamInfo.recently_watched.asset.hours;
    }

    if (
      this.streamInfo.recently_watched.genre_text == undefined &&
      this.videoType == "episode"
    ) {
      if (this.streamInfo.recently_watched.asset != undefined)
        this.streamInfo.recently_watched.genre_text =
          this.streamInfo.recently_watched.asset.genre_text != undefined
            ? this.streamInfo.recently_watched.asset.genre_text
            : this.streamInfo.genre_text;
      else
        this.streamInfo.recently_watched.genre_text =
          this.streamInfo.genre_text == undefined
            ? ""
            : this.streamInfo.genre_text;
    } else {
      this.streamInfo.recently_watched.genre_text =
        this.streamInfo.genre_text == undefined
          ? ""
          : this.streamInfo.genre_text;
    }


    if (this.streamInfo.recently_watched.synopsis == undefined) {
      this.streamInfo.recently_watched.synopsis = this.streamInfo.synopsis;
    }
    if (this.streamInfo.recently_watched.season_id == undefined) {
      this.streamInfo.recently_watched.season_id = this.streamInfo.season_id;
    }
    if (this.streamInfo.recently_watched.show_id == undefined) {
      this.streamInfo.recently_watched.show_id = this.streamInfo.show_id;
    }

    console.log('this.streamInfo', this.streamInfo);

    try {
      this.currentPlayingCode =
        this.streamInfo.recently_watched.code == undefined
          ? (this.streamInfo.seek_bar_data ? this.streamInfo.seek_bar_data.code : '')
          : this.streamInfo.recently_watched.code;
      console.log(this.currentPlayingCode);
      this.currentPlayingMongoId =
        this.streamInfo.recently_watched.asset_mongo_id; //== undefined ? this.streamInfo.asset_mongo_id : this.streamInfo.recently_watched.asset_mongo_id ;
      this.currentPlayingName = this.streamInfo.name; //== undefined ? this.streamInfo.asset_mongo_id : this.streamInfo.recently_watched.asset_mongo_id ;
      this.currentPlayingCharegCode =
        this.streamInfo.recently_watched.charge_code; //== undefined ? this.streamInfo.charge_code : this.streamInfo.recently_watched.charge_code;
      this.currentPlayingContentAvailability =
        this.streamInfo.recently_watched.content_availability; //== undefined ? this.streamInfo.content_availability : this.streamInfo.recently_watched.content_availability;
      this.currentPlayingThumb = this.streamInfo.recently_watched.thumbnail; // == undefined ? this.streamInfo.thumbnail : this.streamInfo.recently_watched.thumbnail;
      this.currentPlayingSec = this.streamInfo.recently_watched.sec; // == undefined ? this.streamInfo.recently_watched.sec == undefined ? this.streamInfo.sec : this.streamInfo.recently_watched.sec : this.streamInfo.recently_watched.asset.sec;

      this.currentPlayingMin = this.streamInfo.recently_watched.min; // this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.min == undefined ? this.streamInfo.min : this.streamInfo.recently_watched.min : this.streamInfo.recently_watched.asset.min;
      this.currentPlayingHour = this.streamInfo.recently_watched.hours; // this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.hours == undefined ? this.streamInfo.hours : this.streamInfo.recently_watched.hours : this.streamInfo.recently_watched.asset.hours;
      this.currentPlayingDuration = this.streamInfo.recently_watched.duration; // this.streamInfo.recently_watched.asset == undefined ? this.streamInfo.recently_watched.hours == undefined ? this.streamInfo.hours : this.streamInfo.recently_watched.hours : this.streamInfo.recently_watched.asset.hours;
      this.currentPlayingGenreText =
        this.streamInfo.recently_watched.genre_text; //== undefined ? this.streamInfo.genre_text : this.streamInfo.recently_watched.genre_text;
      this.currentPlayingGenre =
        // Object.keys(this.streamInfo.recently_watched.genre).length == 1
        //   ? this.streamInfo.recently_watched.genre[0]
        //   : ""; // this.streamInfo.recently_watched.genre == undefined ? Object.keys( this.streamInfo.genre).length == 1 ? this.streamInfo.genre[0]: '' : this.streamInfo.recently_watched.genre[0] ;
        this.currentPlayingLanguages = this.streamInfo.recently_watched.languages; //== undefined ? this.streamInfo.languages : this.streamInfo.recently_watched.languages;
      this.currentPlayingSys = this.streamInfo.recently_watched.synopsis; //== undefined ? this.streamInfo.synopsis : this.streamInfo.recently_watched.synopsis;
      this.currentPlayingShowID = this.streamInfo.recently_watched.show_id; // == undefined ? this.streamInfo.show_id : this.streamInfo.recently_watched.show_id;
      this.currentPlayingSeasonId = this.streamInfo.recently_watched.season_id; //== undefined ? this.streamInfo.season_id : this.streamInfo.recently_watched.season_id;

    } catch (e) {
      console.log(e);
    }

    if (this.streamInfo.seasons != undefined && !this.streamInfo.is_trailer) {
      this.preNextVideo();
    }
    if (this.streamInfo.is_trailer && this.streamInfo.is_trailer_list_index) {
      this.trailerPreviewList();
    }

    console.log(this.streamInfo);


    let videoStreamObj: any = {
      nmode: "WIFI",
      pushId: "",
      ct: "1",
      build: 10000,
      charge_code: this.currentPlayingCode,
      appv: "1.0.00",
      push_date: "",
      air: "airip",
      plateform: "web",
    };
    // if trailer exist

    if (this.streamInfo.is_trailer && this.streamInfo.new_trailer.length > 0) {
      // this.streamInfo.recently_watched.stop = 0;
      videoStreamObj.charge_code = this.streamInfo.code;
      this.is_trailer = this.streamInfo.is_trailer;
      const trailer_index = this.streamInfo.is_trailer_list_index ? this.streamInfo.is_trailer_list_index : 0;
      videoStreamObj.newtrailerId = this.streamInfo.new_trailer[trailer_index].trailer_id;
    }


    if (this.streamInfo.is_liveEvenet && this.streamInfo.type == "event") {
      this.is_live_evnet = this.streamInfo.is_liveEvenet;
    }


    let urlEncodedStreamInfo =
      this.UserService.convertoJSONToUrlEncoded(videoStreamObj);
    console.log('this.videoType', this.videoType);
    console.log('urlEncodedStreamInfo', urlEncodedStreamInfo);
    if (this.streamInfo.is_preview) {
      // debugger
      this.playVideoWithUrl(this.streamInfo.preview[0]);
    }
    else {
      this.playVideoWithAPI(urlEncodedStreamInfo);
    }
    let is_check_device = JSON.parse(localStorage.getItem('is_check_device'));
    console.log('is_check_device', is_check_device);

    this.getDeviceInfo();

    //////////// for uuid ////////////////
    this.getDeviceInfo();
    if (is_check_device) {
      if (this.streamInfo.plan !=undefined && this.streamInfo.plan!='' &&  Object.keys(this.streamInfo.plan).length > 0) {
        this.getIPAddress();
        this.interval_ID = setInterval(() => {
          this.getIPAddress();
        }, this.streamInfo.plan.api_duration);
      }
    }


    // for custom button on tool baar
    // let self = this;
    //   var button = videojs.getComponent('Button');
    //   var closeButton = videojs.extend(button, {
    //     constructor: function () {
    //       button.apply(this, arguments);
    //       this.controlText("Exit Course");
    //       this.addClass('vjs-icon-cancel');
    //     },
    //     handleClick: function () {
    //       self.disableModal();
    //       if (self.playermodal)
    //         self.playermodal.dispose();

    //       self.playermodal = '';

    //     }
    //   });
    //   videojs.registerComponent('closeButton', closeButton);
    //   this.playermodal.getChild('controlBar').addChild('closeButton', {});

    //   var myButton = player.controlBar.addChild('button', {
    //     text: "Press me",
    //     // other options
    //   });
    //   myButton.addClass("html-classname");

    //   var Button = videojs.getComponent('Button');
    // var button = new Button(player, {
    //   clickHandler: function(event) {
    //     videojs.log('Clicked');
    //   }
    // });


  }

  getDeviceInfo() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log(this.deviceInfo);
    console.log(isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log(isTablet);  // returns if the device us a tablet (iPad etc)
    console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  encryptData(data, iv, key) {

    if (typeof data == "string") {
      data = data.slice();
      this.encrypted_UUID = CryptoJS.AES.encrypt(data, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    }
    else {
      this.encrypted_UUID = CryptoJS.AES.encrypt(JSON.stringify(data), key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    }
    return this.encrypted_UUID.toString();
  }
  decryptData(encrypted, iv, key) {
    var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8)
  }

  getIPAddress() {
    this.http.get("https://api.ipify.org/?format=json").subscribe((res: any) => {
      this.ipAddress = res.ip;
      // this.plainText = this.sessionId+ '-'+ this.ipAddress;

      this.data = this.sessionId + '-' + this.ipAddress + '-' + this.deviceInfo.os_version;
      console.log('ipAddress', this.ipAddress);
      console.log('sessionId', this.sessionId);
      console.log('this.data', this.data);

      this.encrypted_UUID = this.encryptData(this.data, this.iv, this.key);
      console.log(this.encrypted_UUID);//genrated encryption String:  swBX2r1Av2tKpdN7CYisMg==

      var decrypteddata = this.decryptData(this.encrypted_UUID, this.iv, this.key);
      console.log(decrypteddata);//genrated decryption string:  Example1
      this.callDeviceApi();
    });
  }




  callDeviceApi() {
    let alloweddevice = this.streamInfo.plan.allowed_device;
    let allowedOs = this.streamInfo.plan.allowed_os;
    let obj: any = {
      catalogue: CatlougeTypeConstants.catlogue,
      session_id: this.sessionId,
      code: this.currentPlayingCode,
      device_id: this.encrypted_UUID,
      device_type: 'computer',
      date: Date.now(),
      plan: {
        allowed_device: alloweddevice,
        allowed_os: allowedOs
      }
    }

    let urlEncodedBodyData = this.UserService.convertoJSONToUrlEncoded(obj)
    urlEncodedBodyData = JSON.stringify(obj);
    console.log(urlEncodedBodyData);
    console.log(obj);

    let allowsArr = this.streamInfo.plan.allowed_os;
    if(allowsArr!="" && allowsArr!=undefined && allowsArr.length > 0){
    if (allowsArr.includes('computer')) {
      this.deviceApiService.checkAllowedDevices('device', urlEncodedBodyData).subscribe(
        (res) => {
          console.log('device api called');
          this.errmsg = res.msg
        },
        (err) => {
          console.log(err.status);
          if (err.status == 400) {
            clearInterval(this.interval_ID);
            // this.removeFromContinueWatch(this)
            this.disableModal();
            if (this.playermodal) this.playermodal.dispose();
            this.shouldCloseAfterPersent = false;
            this.onModalClose.emit(err.error.msg);
          }

        })

    } else {
      clearInterval(this.interval_ID);
      // this.removeFromContinueWatch(this)
      this.disableModal();
      if (this.playermodal) this.playermodal.dispose();
      this.shouldCloseAfterPersent = false;
      this.onModalClose.emit("This device is not allowed for streaming in your existing plan. Kindly upgrade your plan in case you wish to watch on this device.");
    }
  }

  }
  playVideoWithAPI(urlEncodedStreamInfo) {
    this.VideoPlayerService.playUrl(urlEncodedStreamInfo, this.is_trailer, this.is_live_evnet).subscribe(
      (playObj) => {
        let noerror = 1;
        this.watch_credit_status = 1;
        if (playObj.result == undefined || playObj.result == "" || Object.keys(playObj.result).length === 0) {
          if (playObj.error_string) {
            this.router.navigate(["subscribe/choseplan"], {
              queryParams: { code: this.currentPlayingCode },
            });
          }
          this.disableModal();
          return false;
        }


        if (this.videoType == "episode") {
          this.currentLastPlayingSeasonId = playObj.last_video_play_id;
          this.nextVideoCode = playObj.next_episode.code;
          this.currentPlayingData = playObj.assetData;
          this.nextVideoPaidData = playObj.next_episode;
        }


        if (playObj.assetData.trick_play && playObj.assetData.trick_play.image_url) {
          let img_interval = playObj.assetData.trick_play.size;
          let img_width = playObj.assetData.trick_play.width && playObj.assetData.trick_play.width != "" ? playObj.assetData.trick_play.width : 150;
          let img_height = playObj.assetData.trick_play.height && playObj.assetData.trick_play.height != "" ? playObj.assetData.trick_play.height : 84;
          console.log('img_interval', img_interval);
          console.log('img_width', img_width);
          console.log('img_height', img_height);
          invokeSpriteScript(playObj.assetData.trick_play.image_url, img_interval, img_width, img_height);
        }

        this.playUrlResObj = playObj;

        //console.log("this test"+ JSON.stringify(this.playUrlResObj.assetData));

        if (
          playObj.cloud_front &&
          Object.keys(playObj.cloud_front).length > 0
        ) {
          this.videoPlayUrl = playObj.cloud_front.cloud_url+"?"+"User_id="+this.userUnique+"&Device_type="+"web&=Partner=Samsung&Channel_name"+playObj.assetData.name; // './assets/for-free.mp4'; 
          console.log(this.playUrlResObj);
          this.setCookie(
            "CloudFront-Key-Pair-Id",
            playObj.cloud_front.cloudfront_key_pair_id.trim(),
            1
          );
          this.setCookie(
            "CloudFront-Policy",
            playObj.cloud_front.cloudfront_policy.trim(),
            1
          );
          this.setCookie(
            "CloudFront-Signature",
            playObj.cloud_front.cloudfront_signature.trim(),
            1
          );
        } else {
          this.videoPlayUrl = playObj.result+"?"+"User_id="+this.userUnique+"&Device_type="+"web&=Partner=Samsung&Channel_name"+playObj.assetData.name; //'./assets/for-free.mp4'; 
        }

        // if(){

        // }
        //this.videoPlayUrl+"?"+"appname='nexgtv'&assetid="+playObj.code+"&username="+this.userUnique;
        ("http://j-cdr.digivive.com/HLS_Aaj/Aaj.m3u8");
        console.log(this.playUrlResObj);
        if (!this.is_trailer && (playObj.assetData.type == 'episode' || playObj.assetData.type == 'tvshow')) {
          let seasonN = this.streamInfo.totalSeason > 1 ? 'S' + playObj.assetData.show_details.season_number : '';
          this.title = '<div id="show_temp">' +
            '<div class="temp-title">' + playObj.assetData.show_details.show_title + '</div>' +
            '<span class="temp-seasnon">' + seasonN + ' E' + playObj.assetData.show_details.episode_number + ' | ' + '</span>' +
            '<span class="temp-seasnon">' + playObj.assetData.show_details.episode_title + '</span>' + '</div>';

          this.title1 = playObj.assetData.show_details.show_title + 'S' + playObj.assetData.show_details.season_number + ' E' + playObj.assetData.show_details.episode_number + ' | ' +
            + playObj.assetData.show_details.episode_title;

        } else {
          this.title = '<div id="show_temp">' +
            '<span class="temp-seasnon movie_title">' + (playObj.assetData.name || '') + '</span>' + '</div>';
          this.title1 = (playObj.assetData.name || '');
        }

        let advertisement_url = playObj.assetData.advertisement_url;

        let new_source = {
          sources: [{ src: this.videoPlayUrl, type: "application/x-mpegURL" }],
          controls: true,
          autoplay: true,
          withCredentials: true,
          crossDomain: true,
          techOrder: ["html5"],
          html5: {
            hls: {
              overrideNative: true,
              cacheEncryptionKeys: true,
            },
          },
          plugins: {
            dashHlsBitrateSwitcher: {
              support: "both",
            },
            settingsMenu: {
              items: [
                "AudioTrackButton",
                "ChaptersButton",
                "SubsCapsButton",
                "PlaybackRateMenuButton",
                "RatesButton",
              ],
              languages: {
                settings: "Settings",
                loading: "Loading",
                back: "Back",
                captions_off: "Captions Off",
                default_audio: "Default Audio",
                audio: "Audio",
                subtitles: "Subtitles",
                chapters: "Chapters",
                speed: "Speed",
                quality: "Quality",
              },
            },
          },
          crossOrigin: "anonymous",
          liveui: true,
          playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
          nativeControlsForTouch: false
        };

        this.playermodal = videojs("player_one", new_source);

        this.playermodal.maxQualitySelector({
          'maxHeight': 1080 // Do not list any resolutions larger than 480p.
        })

       
        if(this.is_live_evnet){
          // $(".vjs-progress-control").addClass("isLive");
          $(".vjs-play-control").after($(".vjs-live-control"));
        }
        $(".vjs-settings-menu").addClass("vjs-cog-menu-button");
        

        var skipBehindButton = this.playermodal.controlBar.addChild("button");
        var skipBehindButtonDom = skipBehindButton.el();
        skipBehindButtonDom.innerHTML = "<span aria-hidden='true' class='vjs-icon-placeholder'></span><span class='vjs-control-text' aria-live='polite'>Rewind</span>";
        skipBehindButton.addClass("buttonClass");
        skipBehindButton.addClass("vjs-rewind-control");
        $(".vjs-play-control").before(skipBehindButtonDom);

        skipBehindButtonDom.onclick = function () {
          skipS3MV(-10);
        }

        var skipAheadButton = this.playermodal.controlBar.addChild("button");
        var skipAheadButtonDom = skipAheadButton.el();
        skipAheadButtonDom.innerHTML = "<span aria-hidden='true' class='vjs-icon-placeholder'></span><span class='vjs-control-text' aria-live='polite'>Rewind</span>";
        skipAheadButton.addClass("buttonClass");
        skipAheadButton.addClass("vjs-forward-control");
        $(".vjs-play-control").after(skipAheadButtonDom);

        skipAheadButtonDom.onclick = function () {
          skipS3MV(10);
        }

        function skipS3MV(skipBy) {
          videojs("player_one").currentTime(videojs("player_one").currentTime() + skipBy);
        }

        //https://github.com/googleads/videojs-ima
        // let imaOptions = {
        //   adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/30392581/nexgtv_whitelabled_website_video_640x480&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&correlator=[placeholder]&vpmute=0&vpa=click&vpos=preroll',
        //   id: 'videoAds',
        //  disableAdControls:true,
        // };

        videojs.options.hls.overrideNative = true;
        videojs.options.html5.nativeAudioTracks = false;
        videojs.options.html5.nativeTextTracks = false;



        let imaOptions = {
          adTagUrl: advertisement_url, //'http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&vid=short_onecue&correlator=',
          disableAdControls: true,
          id: "videoAds",
        };


        this.playermodal.ima(imaOptions);
        google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);


        var startEvent = "click";
        if (
          navigator.userAgent.match(/iPhone/i) ||
          navigator.userAgent.match(/iPad/i) ||
          navigator.userAgent.match(/Android/i)
        ) {
          startEvent = "touchend";
        }
        let _this = this;
        this.playermodal.one(startEvent, function () {
          _this.playermodal.ima.initializeAdDisplayContainer();
        });




        this.playermodal.poster(playObj.assetData.image);


        //https://www.nuevodevel.com/nuevo/doc
        // this.playermodal.nuevo({
        //   videoInfo: true,
        //   infoTitle: this.title,
        //   infoIcon: "",
        //   qualityMenu: true,
        //   pipButton: false,
        //   settingButton: true,
        //   buttonRewind: true,
        //   buttonForward: true,
        //   resume: true,
        //   // poster: playObj.assetData.image,
        //   errordisplay: false,
        //   shareMenu: false,
        //   contextMenu: false,
        //   // slideImage: "https://p8tst.s3.ap-south-1.amazonaws.com/thumb/rra/150/RRA_sprite.jpg",
        //   // slideType: 'vertical',
        //   // //slideWidth: 160,  //optional
        //   // //slideHeight: 90  //optional
        // });



        if (playObj.assetData.trick_play && playObj.assetData.trick_play.image_url) {
          this.playermodal.spriteThumbnails({
            interval: playObj.assetData.trick_play.size,
            url: playObj.assetData.trick_play.image_url,
            width: playObj.assetData.trick_play.width && playObj.assetData.trick_play.width != '' ? playObj.assetData.trick_play.width : 150,
            height: playObj.assetData.trick_play.height && playObj.assetData.trick_play.height != '' ? playObj.assetData.trick_play.height : 84
          });
        }


        $(".player_one-dimensions.vjs-fluid").css(
          "height",
          `${window.innerHeight}px`
        );

        $(window).bind("orientationchange", function (event) {
          let height = window.innerHeight;
          $(".player_one-dimensions.vjs-fluid").css("height", `${height}px`);
        });

        $(window).resize(function () {
          let height = window.innerHeight;
          $(".player_one-dimensions.vjs-fluid").css("height", `${height}px`);
        });




        let newSelf = this;
        this.playermodal.ready((event) => {

          // event.preventDefault();

          let playingCode =
            this.streamInfo.recently_watched.code == undefined
              ? (this.streamInfo.seek_bar_data ? this.streamInfo.seek_bar_data.code : '')
              : this.streamInfo.recently_watched.code;

          if (this.streamInfo.recently_watched.stop != undefined || this.streamInfo.is_trailer) {

            let preTime = this.streamInfo.is_trailer ? 0 : Math.round(this.streamInfo.recently_watched.stop);
            var _this = this;
            if (advertisement_url == "") {
              this.playermodal.one("loadedmetadata", function () {
                console.log("setting current time to 60 seconds " + preTime);
                _this.playermodal.currentTime(preTime);
              });
            }


            this.playermodal.on("adend", function () {
              // 3. Re-enable volume, restart video from beginning
              console.log(playingCode + " == " + _this.currentPlayingCode);
              if (playingCode == _this.currentPlayingCode) {
                _this.playermodal.currentTime(preTime);
              } else {
                // alert('513')
                _this.playermodal.play();
              }
            });

            this.playermodal.on("complete", function (e) {
              console.log(e);
            });
            this.playermodal.on("adserror", function (e) {
              console.log(e);
            });
            // this.playermodal.currentTime(preTime);
          }
          var topTitle = '<span class="vjs-info vjs-info-top" style="pointer-events: none;"><span class="vjs-text"><span class="vjs-ttl">' + this.title + '</span></span></span>';
          $(".ima-ad-container").after(topTitle);
          $(".vjs-text").append(
            '<span><i class="fa fa-arrow-left vjs-ttl_btn" id="vjs-ttl_btn"></i></span>'
          );

          // $(".uaRationOnPLayer").append(
          //   '<i class="vjs-ttl_btn" id="vjs-ttl_btn">UA 17</i>'
          // );

          // alert("check testss");

          let buttonfwd = document.getElementById("vjs-ttl_btn");

          buttonfwd.ontouchstart = (event) => {
            this.stopVideo();
            event.stopPropagation();

            event.preventDefault();

            this.disableModal();
            if (this.playermodal) this.playermodal.dispose();

            this.playermodal = "";

            return false;
          };

          buttonfwd.onclick = (event) => {
            console.log("buttonfwd.onclick");
            this.action1 = 'stop';
            this.stopVideo();
            event.stopPropagation();

            event.preventDefault();

            this.disableModal();
            if (this.playermodal) this.playermodal.dispose();
            // alert('this.playermodal' +this.playermodal)
            //$('body').removeClass('vjs-full-window');
            this.playermodal = "";

            return false;
          };

          let next_template =
            '<span class="next_text">Next: </span>' +
            '<div class="_progress_play"><img class="next_thumb" src="' +
            this.nextPlayingThumb +
            '"><span><i class="fa fa-play" aria-hidden="true"></i></span></div><div class="_serial_parent"><span class="serial_number">' +
            this.nextSeasonNumber +
            this.nextEpisodeNumber +
            ': </span><span class="episode_name">' +
            this.nextVideoName +
            '</span><span class="left_miun">' +
            this.nextVideoDuration +
            "</span></div>";
          let next_button =
            '<button class="next_button btn btn-primary" id="next_button" type="button" title="Next Video" aria-disabled="false"> ' +
            next_template +
            "</button>";
          $(".vjs-big-play-button").after(next_button);

          $("#next_button").css({
            background: "rgb(2 2 2 / 100%)",
            width: "130px",

            top: "50%",
            right: "1%",
            cursor: "pointer",
            "z-index": "3",
            position: "absolute",
            display: "none",
            padding: "0",
            border: "0",
            outline: "0 !important",
            "border-radius": "4px",

          });

          let nextbutton = document.getElementById("next_button");

          nextbutton.onclick = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.nextButtonAction(event);
          };

          nextbutton.ontouchstart = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.nextButtonAction(event);
          };

          let pre_template =
            '<span class="previous_text">Previous: </span>' +
            '<div class="_progress_play"><img class="pre_thumb" src="' +
            this.prePlayingThumb +
            '"><span><i class="fa fa-play" aria-hidden="true"></i></span></div><div class="_serial_parent"><span class="serial_number">' +
            this.preSeasonNumber +
            this.preEpisodeNumber +
            ': </span><span class="episode_name">' +
            this.preVideoName +
            '</span><span class="left_miun">' +
            this.preVideoDuration +
            "</span> </div>";
          let back_button =
            '<button class="back_button btn btn-primary" id="back_button" type="button" title="Previous Video" aria-disabled="false">' +
            pre_template +
            "</button>";

          $(".vjs-big-play-button").after(back_button);

          $("#back_button").css({
            background: "rgb(35 36 37 / 64%)",
            width: "18%",
            top: "50%",
            "margin-top": "-2%",
            "margin-left": "1%",
            cursor: "pointer",
            "z-index": "3",
            position: "absolute",
            display: "none",
            padding: "0",
            border: "0",
            outline: "0 !important",
            "border-radius": "10px",
            transform: "translateY(-50%)",
          });

          let backbutton = document.getElementById("back_button");

          backbutton.onclick = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.backButtonAction(event);
          };

          backbutton.ontouchstart = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.backButtonAction(event);
          };

          // Skip intro button & Click Functionlity
          console.log(this.streamInfo);
          let skip_intro_button = `<button class="skip_intro_button btn btn-primary" id="skip_intro_button" type="button" title="Skip Intro" aria-disabled="false">
            ${this.playUrlResObj.assetData.skip_intro_text
              ? this.playUrlResObj.assetData.skip_intro_text
              : "Skip Intro"
            }</button>`;

          $(".vjs-big-play-button").after(skip_intro_button);

          $("#skip_intro_button").css({
            background: "rgb(2 2 2 / 100%)",
            width: "130px",
            top: "50%",
            "margin-top": "-2%",
            right: "10%",
            cursor: "pointer",
            "z-index": "3",
            position: "absolute",
            display: "none",
          });

          let skipIntroButton = document.getElementById("skip_intro_button");

          skipIntroButton.onclick = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.skipIntroButtonAction();
          };

          skipIntroButton.ontouchstart = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.skipIntroButtonAction();
          };

          // Skip intro button & Click Functionlity show_temp
          this.videoLableButtonRander();


          let skipCastButton = document.getElementById("skip_cast_button");

          skipCastButton.onclick = (event) => {
            event.stopPropagation();
            event.preventDefault();
            console.log("skipCastButton");
            this.videoLabelButtonAction(this.videoLabelObj);
          };

          skipCastButton.ontouchstart = (event) => {
            event.stopPropagation();
            event.preventDefault();

            this.videoLabelButtonAction(this.videoLabelObj);
          };
        });

        // let self = this;
        let isPlaced: boolean = false;
        this.playermodal.on("timeupdate", () => {
          this.currentPlayingTime = this.playermodal.currentTime();
          if (this.playUrlResObj.assetData.video_label) {
            this.videoLabelObj =
              this.playUrlResObj.assetData.video_label.filter((n) => {
                // console.log(n)
                // console.log(this.playermodal.currentTime());
                return (
                  parseInt(this.playermodal.currentTime()) >
                  parseInt(n.start) &&
                  parseInt(this.playermodal.currentTime()) < parseInt(n.end)
                );
              });
            // self.videoLabelObj.is_watch_credit = self.watch_credit_status;
            // console.log(this.videoLabelObj);

            if (this.watch_credit_status == 1) {
              if (this.videoLabelObj.length > 0) {
                $(".skip_cast_button").css({ display: "block" });
                // alert('this.videoLabelObj[0].label' + this.videoLabelObj[0].label);
                $(".skip_cast_button").html(this.videoLabelObj[0].label);
              } else {
                $(".skip_cast_button").css({ display: "none" });
              }
            }
          }

          if (
            this.playermodal.remainingTime() <= 31 &&
            this.watch_credit_status == "1" &&
            this.videoType == "episode" &&
            this.nextVideoCode != "" && this.nextVideoCode != undefined
          ) {

            if ($("#next_button").css("display") == "none") {
              let next_template =
                '<div class="_progress_play"><div class="progress_move_background">Next Episode</div></div>' +
                "</div>";
              //$('#next_button').html('<span class="next_text">Next: </span>'+'<img class="next_thumb" src="'+self.nextPlayingThumb+'">' + self.nextVideoName + self.nextSeasonNumber + self.nextEpisodeNumber);
              $("#next_button").html(next_template);
              $("#next_button").css({ display: "block" });
              $("#skip_cast_button").css({ right: "12%" });
            }

          } else {
            $("#next_button").css({ display: "none" });
          }

          let totalDuration = Math.round(this.playermodal.duration());
          let playedDuration = Math.round(this.playermodal.currentTime());


          let playingDuration = (playedDuration * 100) / totalDuration;
          if (playingDuration >= 99.98 && this.shouldCloseAfterPersent) {
            // alert('904')
            if (this.videoType == "episode") {
              this.shouldCloseAfterPersent = false;
              console.log(`episode Next Vide Code ${this.nextVideoCode}`)
              if (this.nextVideoCode == "" || this.nextVideoCode == undefined) {
                console.log(`it's last episode so player should be closed`)
                this.shouldCloseAfterPersent = false;
                this.disableModal();
                if (this.playermodal) this.playermodal.dispose();

                return;
              } else {
                if (this.nextEpisodeIsBlank) {
                  this.nextButtonAction("");
                  setInterval(() => {
                    this.shouldCloseAfterPersent = true;
                  }, 10000);

                  $("#uaRationOnPLayer").remove();
                }

              }
            } else {
              this.removeFromContinueWatch(this)
              this.disableModal();
              if (this.playermodal) this.playermodal.dispose();
              this.shouldCloseAfterPersent = false;
              return;
            }

          }

          // alert('playingDuration : ' +  playingDuration + ' && ' + 'this.isHudredPersent' +  this.isHudredPersent)
          if (playingDuration >= 98 && this.isHudredPersent) {
            // alert('98')
            console.log(this.playermodal.currentTime());
            this.removeFromContinueWatch(this)
            this.isHudredPersent = false;
          }
        });

        this.playermodal.on("seeking", (event) => {

          if (this.playermodal.remainingTime() <= 31) {
            let remainingTime = Math.round(this.playermodal.remainingTime()) + 3 + 's ';
            $('.progress_move_background').css('animation-duration', remainingTime);
          }
        });
        let name = this.title1;
        let type = this.streamInfo.type;
        let code = this.streamInfo.recently_watched.code;
        let action = 'Playing';

        this.playermodal.on("play", (event) => {
          $('.progress_move_background').removeClass("animation-paused");
          this.action1 = 'start';
          action = 'Playing';
          this.googleEvent.eventEmitter('users_video', 'users_video', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
          this.googleEvent.eventEmitter('users', 'users', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
          this.stopVideo();
        });

        if (this.streamInfo.recently_watched.duration > 0) {
          this.playermodal.on("seeked", (event) => {
            this.action1 = 'seek';
            this.stopVideo();
          });
        }

        this.playermodal.on("pause", (event) => {
          $('.progress_move_background').addClass("animation-paused");
          action = 'Paused';
          this.action1 = 'paused';

          this.googleEvent.eventEmitter('users_video', 'users_video', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
          this.googleEvent.eventEmitter('users', 'users', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);

          this.stopVideo();
        });
        this.playermodal.on("fullWindowOnEscKey", (event) => { });

        this.playermodal.on("ended", (event) => {
          console.log("ended");

          action = 'Stopped';
          this.action1 = 'stop';

          this.googleEvent.eventEmitter('users_video', 'users_video', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
          this.googleEvent.eventEmitter('users', 'users', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
          this.stopVideo();
        });
        this.playermodal.on("dispose", (event) => {
          this.jwPlayerService.closePlayer.next(true);
          clearInterval(this.interval_ID);
        });
        this.playermodal.on("error", (event) => {
          console.error(event);
        });

        //}, 500);
      }
    );
  }
  playVideoWithUrl(playObj) {

    let noerror = 1;
    this.watch_credit_status = 1;
    // if (playObj.preview_url == undefined || playObj.preview_url == "" || Object.keys(playObj.result).length === 0) {
    //   if (playObj.error_string) {
    //     this.router.navigate(["subscribe/choseplan"], {
    //       queryParams: { code: this.currentPlayingCode },
    //     });
    //   }
    //   this.disableModal();
    //   return false;
    // }

    this.playUrlResObj = playObj;
    console.log(this.playUrlResObj);


    if (
      playObj.cloud_front &&
      Object.keys(playObj.cloud_front).length > 0
    ) {
      this.videoPlayUrl = playObj.cloud_front.cloud_url;
      console.log(this.playUrlResObj);
      this.setCookie(
        "CloudFront-Key-Pair-Id",
        playObj.cloud_front.cloudfront_key_pair_id.trim(),
        1
      );
      this.setCookie(
        "CloudFront-Policy",
        playObj.cloud_front.cloudfront_policy.trim(),
        1
      );
      this.setCookie(
        "CloudFront-Signature",
        playObj.cloud_front.cloudfront_signature.trim(),
        1
      );
    } else {
      this.videoPlayUrl = playObj.preview_url;
    }
    ("http://j-cdr.digivive.com/HLS_Aaj/Aaj.m3u8");
    console.log(this.playUrlResObj);
    //https://github.com/videojs/video.js/blob/master/src/js/player.js#L212
    // https://docs.videojs.com/docs/api/player.html


    // let uiRatingTimeoutIS = this.playUrlResObj.assetData.content_rating.show_duration * 1000;
    // let title_template =
    //   '<div id="show_temp">' +
    //   '<div class="temp-title">' + playObj.assetData.show_details.episode_title + '</div>' +
    //   '<span class="temp-seasnon">' + 'S' + playObj.assetData.show_details.season_number + ' E' + playObj.assetData.show_details.episode_number + ' | ' + '</span>' +
    //   '<span class="temp-seasnon">' + playObj.assetData.show_details.show_title + '</span>' + '</div>';

    // if (playObj.assetData.type == 'episode' || playObj.assetData.type == 'tvshow') {
    //   this.title = title_template;
    // } else {
    //   if (playObj.assetData.name != undefined)
    //     this.title = playObj.assetData.name;
    //   else this.title = playObj.assetData.channel_name;
    // }
    this.title = '<div id="show_temp">' +
      '<span class="temp-seasnon movie_title">' + playObj.preview_title + '</span>' + '</div>';
    // if (playObj.preview_url) {
    //   this.title = '<div id="show_temp">' +
    //     '<div class="temp-title">' + playObj.preview_title + '</div>' +
    //     '<span class="temp-seasnon">' + 'S' + playObj.assetData.show_details.season_number + ' E' + playObj.assetData.show_details.episode_number + ' | ' + '</span>' +
    //     '<span class="temp-seasnon">' + playObj.assetData.show_details.episode_title + '</span>' + '</div>';

    // } else {
    //   this.title = '<div id="show_temp">' +
    //     '<span class="temp-seasnon movie_title">' + playObj.assetData.name + '</span>' + '</div>';
    // }

    let advertisement_url = '';

    let new_source = {
      sources: [{ src: this.videoPlayUrl, type: "application/x-mpegURL" }],
      controls: true,
      autoplay: true,
      withCredentials: true,
      crossDomain: true,
    };

    this.playermodal = videojs("player_one", new_source);
    var muted = this.playermodal.muted(true);
    //https://github.com/googleads/videojs-ima
    // let imaOptions = {
    //   adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/30392581/nexgtv_whitelabled_website_video_640x480&description_url=[placeholder]&tfcd=0&npa=0&sz=640x480&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&correlator=[placeholder]&vpmute=0&vpa=click&vpos=preroll',
    //   id: 'videoAds',
    //  disableAdControls:true,
    // };

    videojs.options.hls.overrideNative = true;
    videojs.options.html5.nativeAudioTracks = false;
    videojs.options.html5.nativeTextTracks = false;

    let imaOptions = {
      adTagUrl: advertisement_url, //'http://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/124319096/external/ad_rule_samples&ciu_szs=300x250&ad_rule=1&impl=s&gdfp_req=1&env=vp&output=xml_vmap1&unviewed_position_start=1&cust_params=sample_ar%3Dpremidpostpod%26deployment%3Dgmf-js&cmsid=496&vid=short_onecue&correlator=',
      disableAdControls: true,
      id: "videoAds",
    };

    this.playermodal.ima(imaOptions);
    google.ima.settings.setDisableCustomPlaybackForIOS10Plus(true);
    var startEvent = "click";
    if (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/Android/i)
    ) {
      startEvent = "touchend";
    }
    let _this = this;
    this.playermodal.one(startEvent, function () {
      _this.playermodal.ima.initializeAdDisplayContainer();
    });
    // this.playermodal.poster(playObj.assetData.image);
    //https://www.nuevodevel.com/nuevo/doc
    this.playermodal.nuevo({
      videoInfo: true,
      infoTitle: this.title,
      infoIcon: "",
      qualityMenu: true,
      pipButton: false,
      settingButton: true,
      buttonRewind: true,
      buttonForward: true,
      resume: true,
      // poster: playObj.assetData.image,
      errordisplay: false,
      shareMenu: false,
      contextMenu: false,
      // slideImage: "https://p8tst.s3.ap-south-1.amazonaws.com/thumb/rra/150/RRA_sprite.jpg",
      // slideType: 'vertical',
      // //slideWidth: 160,  //optional
      // //slideHeight: 90  //optional
    });

    // if (playObj.assetData.trickplay && playObj.assetData.trickplay.image_url) {
    //   this.playermodal.spriteThumbnails({
    //     interval: 10,
    //     url: playObj.assetData.trickplay.image_url,
    //     width: 160,
    //     height: 90
    //   });
    // }


    $(".player_one-dimensions.vjs-fluid").css(
      "height",
      `${window.innerHeight}px`
    );

    $(window).bind("orientationchange", function (event) {
      let height = window.innerHeight;
      $(".player_one-dimensions.vjs-fluid").css("height", `${height}px`);
    });

    $(window).resize(function () {
      let height = window.innerHeight;
      $(".player_one-dimensions.vjs-fluid").css("height", `${height}px`);
    });

    this.playermodal.on("fullscreenchange", (event) => { });
    let newSelf = this;
    this.playermodal.on("nuevoReady", (event) => {
      event.preventDefault();

      let playingCode =
        this.streamInfo.recently_watched.code == undefined
          ? this.streamInfo.seek_bar_data.code
          : this.streamInfo.recently_watched.code;

      if (this.streamInfo.recently_watched.stop != undefined || this.streamInfo.is_trailer) {

        let preTime = Math.round(this.streamInfo.recently_watched.stop);
        var _this = this;
        if (advertisement_url == "") {
          this.playermodal.one("loadedmetadata", function () {
            console.log("setting current time to 60 seconds " + preTime);
            _this.playermodal.currentTime(preTime);
          });
        }

        this.playermodal.on("adend", function () {
          // 3. Re-enable volume, restart video from beginning
          console.log(playingCode + " == " + _this.currentPlayingCode);
          if (playingCode == _this.currentPlayingCode) {
            _this.playermodal.currentTime(preTime);
          } else {
            // alert('513')
            _this.playermodal.play();
          }
        });
        this.playermodal.on("complete", function (e) {
          console.log(e);
        });
        this.playermodal.on("adserror", function (e) {
          console.log(e);
        });
        // this.playermodal.currentTime(preTime);
      }

      $(".vjs-text").append(
        '<i class="fa fa-arrow-left vjs-ttl_btn" id="vjs-ttl_btn"></i>'
      );

      // $(".uaRationOnPLayer").append(
      //   '<i class="vjs-ttl_btn" id="vjs-ttl_btn">UA 17</i>'
      // );


      let buttonfwd = document.getElementById("vjs-ttl_btn");

      buttonfwd.ontouchstart = (event) => {
        this.stopVideo();
        event.stopPropagation();

        event.preventDefault();

        this.disableModal();
        if (this.playermodal) this.playermodal.dispose();

        this.playermodal = "";

        return false;
      };

      buttonfwd.onclick = (event) => {
        console.log("buttonfwd.onclickdd");
        // this.stopVideo();
        event.stopPropagation();

        event.preventDefault();

        this.disableModal();
        if (this.playermodal) this.playermodal.dispose();
        // alert('this.playermodal' +this.playermodal)
        //$('body').removeClass('vjs-full-window');
        this.playermodal = "";

        return false;
      };

      let next_template =
        '<span class="next_text">Next: </span>' +
        '<div class="_progress_play"><img class="next_thumb" src="' +
        this.nextPlayingThumb +
        '"><span><i class="fa fa-play" aria-hidden="true"></i></span></div><div class="_serial_parent"><span class="serial_number">' +
        this.nextSeasonNumber +
        this.nextEpisodeNumber +
        ': </span><span class="episode_name">' +
        this.nextVideoName +
        '</span><span class="left_miun">' +
        this.nextVideoDuration +
        "</span></div>";
      let next_button =
        '<button class="next_button btn btn-primary" id="next_button" type="button" title="Next Video" aria-disabled="false"> ' +
        next_template +
        "</button>";
      $(".vjs-big-play-button").after(next_button);

      $("#next_button").css({
        background: "rgb(2 2 2 / 100%)",
        width: "130px",

        top: "50%",
        right: "1%",
        cursor: "pointer",
        "z-index": "3",
        position: "absolute",
        display: "none",
        padding: "0",
        border: "0",
        outline: "0 !important",
        "border-radius": "4px",

      });

      let nextbutton = document.getElementById("next_button");

      nextbutton.onclick = (event) => {
        event.stopPropagation();
        event.preventDefault();

        this.nextButtonAction(event);
      };

      nextbutton.ontouchstart = (event) => {
        event.stopPropagation();
        event.preventDefault();

        this.nextButtonAction(event);
      };

      let pre_template =
        '<span class="previous_text">Previous: </span>' +
        '<div class="_progress_play"><img class="pre_thumb" src="' +
        this.prePlayingThumb +
        '"><span><i class="fa fa-play" aria-hidden="true"></i></span></div><div class="_serial_parent"><span class="serial_number">' +
        this.preSeasonNumber +
        this.preEpisodeNumber +
        ': </span><span class="episode_name">' +
        this.preVideoName +
        '</span><span class="left_miun">' +
        this.preVideoDuration +
        "</span> </div>";
      let back_button =
        '<button class="back_button btn btn-primary" id="back_button" type="button" title="Previous Video" aria-disabled="false">' +
        pre_template +
        "</button>";
      $(".vjs-big-play-button").after(back_button);

      $("#back_button").css({
        background: "rgb(35 36 37 / 64%)",
        width: "18%",
        top: "50%",
        "margin-top": "-2%",
        "margin-left": "1%",
        cursor: "pointer",
        "z-index": "3",
        position: "absolute",
        display: "none",
        padding: "0",
        border: "0",
        outline: "0 !important",
        "border-radius": "10px",
        transform: "translateY(-50%)",
      });

      let backbutton = document.getElementById("back_button");

      backbutton.onclick = (event) => {
        event.stopPropagation();
        event.preventDefault();

        this.backButtonAction(event);
      };

      backbutton.ontouchstart = (event) => {
        event.stopPropagation();
        event.preventDefault();

        this.backButtonAction(event);
      };

      // Skip intro button & Click Functionlity
      console.log(this.streamInfo);
      // let skip_intro_button = `<button class="skip_intro_button btn btn-primary" id="skip_intro_button" type="button" title="Skip Intro" aria-disabled="false">
      //   ${this.playUrlResObj.assetData.skip_intro_text
      //     ? this.playUrlResObj.assetData.skip_intro_text
      //     : "Skip Intro"
      //   }</button>`;

      // $(".vjs-big-play-button").after(skip_intro_button);

      // $("#skip_intro_button").css({
      //   background: "rgb(2 2 2 / 100%)",
      //   width: "130px",
      //   top: "50%",
      //   "margin-top": "-2%",
      //   right: "10%",
      //   cursor: "pointer",
      //   "z-index": "3",
      //   position: "absolute",
      //   display: "none",
      // });

      // let skipIntroButton = document.getElementById("skip_intro_button");

      // skipIntroButton.onclick = (event) => {
      //   event.stopPropagation();
      //   event.preventDefault();

      //   this.skipIntroButtonAction();
      // };

      // skipIntroButton.ontouchstart = (event) => {
      //   event.stopPropagation();
      //   event.preventDefault();

      //   this.skipIntroButtonAction();
      // };

      // Skip intro button & Click Functionlity show_temp
      // this.videoLableButtonRander();


      // let skipCastButton = document.getElementById("skip_cast_button");

      // skipCastButton.onclick = (event) => {
      //   event.stopPropagation();
      //   event.preventDefault();
      //   console.log("skipCastButton");
      //   this.videoLabelButtonAction(this.videoLabelObj);
      // };

      // skipCastButton.ontouchstart = (event) => {
      //   event.stopPropagation();
      //   event.preventDefault();

      //   this.videoLabelButtonAction(this.videoLabelObj);
      // };
    });

    // let self = this;
    let isPlaced: boolean = false;
    this.playermodal.on("timeupdate", () => {
      this.currentPlayingTime = this.playermodal.currentTime();
      // if (this.playUrlResObj.assetData.video_label) {
      //   this.videoLabelObj =
      //     this.playUrlResObj.assetData.video_label.filter((n) => {
      //       // console.log(n)
      //       // console.log(this.playermodal.currentTime());
      //       return (
      //         parseInt(this.playermodal.currentTime()) >
      //         parseInt(n.start) &&
      //         parseInt(this.playermodal.currentTime()) < parseInt(n.end)
      //       );
      //     });
      //   // self.videoLabelObj.is_watch_credit = self.watch_credit_status;
      //   // console.log(this.videoLabelObj);

      //   if (this.watch_credit_status == 1) {
      //     if (this.videoLabelObj.length > 0) {
      //       $(".skip_cast_button").css({ display: "block" });
      //       // alert('this.videoLabelObj[0].label' + this.videoLabelObj[0].label);
      //       $(".skip_cast_button").html(this.videoLabelObj[0].label);
      //     } else {
      //       $(".skip_cast_button").css({ display: "none" });
      //     }
      //   }
      // }

      // console.log(this.videoLabelObj);
      // console.log(this.videoType);
      // console.log(this.nextVideoCode)
      // console.log(this.watch_credit_status);
      // console.log(this.playermodal.remainingTime());

      // alert('763' + this.watch_credit_status )

      if (
        this.playermodal.remainingTime() <= 31 &&
        this.watch_credit_status == "1" &&
        this.videoType == "episode" &&
        this.nextVideoCode != ""
      ) {

        // console.log('is_watch_credit');
        // console.log($("#next_button").css("display"));
        // if ($("#next_button").css("display") == "none") {
        //   let next_template =
        //     '<span class="next_text">Next: </span>' +
        //     '<div class="_progress_play"><img class="next_thumb" src="' +
        //     this.nextPlayingThumb +
        //     '"><span><div class="circular"> <div class="inner"> <i class="fa fa-play" aria-hidden="true"></i> </div><div class="circle"> <div class="bar left"> <div class="_progress_oth"> </div> </div> <div class="bar right"> <div class="_progress_oth"> </div> </div> </div> </div></span></div><div class="_serial_parent"><span class="serial_number"><span class="serial_number">' +
        //     this.nextSeasonNumber +
        //     this.nextEpisodeNumber +
        //     ': </span><span class="episode_name">' +
        //     this.nextVideoName +
        //     '</span><span class="left_miun">' +
        //     this.nextVideoDuration +
        //     "</span></div>";
        //   //$('#next_button').html('<span class="next_text">Next: </span>'+'<img class="next_thumb" src="'+self.nextPlayingThumb+'">' + self.nextVideoName + self.nextSeasonNumber + self.nextEpisodeNumber);
        //   $("#next_button").html(next_template);
        //   $("#next_button").css({ display: "block" });
        // }

        if ($("#next_button").css("display") == "none") {
          let next_template =
            '<div class="_progress_play"><div class="progress_move_background">Next Episode</div></div>' +
            "</div>";
          //$('#next_button').html('<span class="next_text">Next: </span>'+'<img class="next_thumb" src="'+self.nextPlayingThumb+'">' + self.nextVideoName + self.nextSeasonNumber + self.nextEpisodeNumber);
          $("#next_button").html(next_template);
          $("#next_button").css({ display: "block" });
          $("#skip_cast_button").css({ right: "12%" });

          // if(this.playUrlResObj.assetData.video_label.is_watch_credit == "1"){
          //   $("#skip_cast_button").css({ right: "12%" });
          // }else{
          //   $("#skip_cast_button").css({ right: "2%" });
          // }

        }

      } else {
        $("#next_button").css({ display: "none" });
      }

      // alert('this.playermodal.currentTime 2' + this.playermodal.currentTime())

      // console.log(this.lastEpisode)

      // if (
      //   this.playermodal.remainingTime() <= 31 &&
      //   this.watch_credit_status == "1" &&
      //   this.videoType == "episode" &&
      //   this.preVideoCode != ""
      // ) {
      //   if ($("#back_button").css("display") == "none") {
      //     let pre_template =
      //       '<span class="previous_text">Previous: </span>' +
      //       '<div class="_progress_play"><img class="pre_thumb" src="' +
      //       this.prePlayingThumb +
      //       '"><span><i class="fa fa-play" aria-hidden="true"></i></span></div><div class="_serial_parent"><span class="serial_number"><span class="serial_number">' +
      //       this.preSeasonNumber +
      //       this.preEpisodeNumber +
      //       ': </span><span class="episode_name">' +
      //       this.preVideoName +
      //       '</span><span class="left_miun">' +
      //       this.preVideoDuration +
      //       "</span></div>";
      //     //$('#back_button').html('<span class="previous_text">Previous: </span>'+'<img class="next_thumb" src="'+self.prePlayingThumb+'">'  + self.preVideoName + self.preSeasonNumber + self.preEpisodeNumber);
      //     $("#back_button").html(pre_template);
      //     $("#back_button").css({ display: "none" });
      //   }
      // } else {
      //   $("#back_button").css({ display: "none" });
      // }

      // Skip Cast Button Visibility
      // if (
      //   this.playUrlResObj.assetData.cast_intro &&
      //   this.playUrlResObj.assetData.cast_intro.start &&
      //   this.playUrlResObj.assetData.cast_intro.end &&
      //   this.playermodal.currentTime() >
      //   this.playUrlResObj.assetData.cast_intro.start &&
      //   this.playermodal.currentTime() <
      //   this.playUrlResObj.assetData.cast_intro.end
      // ) {
      //   // $(".skip_cast_button").css({ display: "block" });
      // } else {
      //   // $(".skip_cast_button").css({ display: "none" });
      // }

      // Skip Intro Button Visibility
      // if (
      //   this.playUrlResObj.assetData.skip_intro.start &&
      //   this.playUrlResObj.assetData.skip_intro.end &&
      //   this.playermodal.currentTime() >
      //   this.playUrlResObj.assetData.skip_intro.start &&
      //   this.playermodal.currentTime() <
      //   this.playUrlResObj.assetData.skip_intro.end
      // ) {
      //   // $(".skip_intro_button").css({ display: "block" });
      // } else {
      //   // $(".skip_intro_button").css({ display: "none" });
      // }
      // alert('this.playermodal.currentTime 3 ' + this.playermodal.currentTime())

      let totalDuration = Math.round(this.playermodal.duration());
      let playedDuration = Math.round(this.playermodal.currentTime());
      // if (
      //   ((playedDuration * 100) / totalDuration >= 98 &&
      //   (playedDuration * 100) / totalDuration <= 99.8) &&
      //   !this.rmContApiCalledStatus
      // ) {
      //   console.log("98");
      //   this.rmContApiCalledStatus = true;
      //   if (this.videoType == "episode") {
      //     try {
      //       this.nextVideObj["start"] = 0;
      //       this.nextVideObj["stop"] = Math.round(
      //         this.playermodal.currentTime()
      //       );
      //       this.nextVideObj["duration"] = Math.round(
      //         this.playermodal.duration()
      //       );

      //     } catch (e) {}
      //   } else {
      //     // this.rmContApiCalledStatus = false;
      //   }
      //   this.removeFromContinueWatch(this);
      //   // self.sendNextEpisode(self.nextVideObj);
      // }
      // else{
      //   self.rmContApiCalledStatus = false;
      // }
      // if (((playedDuration * 100) / totalDuration) >= 98.80){
      //   self.rmContApiCalledStatus = false;
      // }

      // console.log('(playedDuration * 100) / totalDuration) ' + (playedDuration * 100) / totalDuration);
      // alert('playedDuration' + playedDuration)

      let playingDuration = (playedDuration * 100) / totalDuration;
      if (playingDuration >= 99.98 && this.shouldCloseAfterPersent) {
        // alert('904')
        if (this.videoType == "episode") {
          console.log(`episode Next Vide Code ${this.nextVideoCode}`)
          if (this.nextVideoCode == "" || this.nextVideoCode == undefined) {
            console.log(`it's last episode so player should be closed`)
            this.shouldCloseAfterPersent = false;
            this.disableModal();
            if (this.playermodal) this.playermodal.dispose();

            return;
          } else {
            this.nextButtonAction("");
            this.shouldCloseAfterPersent = true;
            $("#uaRationOnPLayer").remove();

          }
        } else {
          this.removeFromContinueWatch(this)
          this.disableModal();
          if (this.playermodal) this.playermodal.dispose();
          this.shouldCloseAfterPersent = false;
          return;
        }

      }

      // alert('playingDuration : ' +  playingDuration + ' && ' + 'this.isHudredPersent' +  this.isHudredPersent)
      if (playingDuration >= 98 && this.isHudredPersent) {
        // alert('98')
        console.log(this.playermodal.currentTime());
        this.removeFromContinueWatch(this)
        this.isHudredPersent = false;
        // if (this.videoType == "episode") {

        //   if (this.nextVideoCode != "" && this.nextVideoCode != undefined) {
        //     console.log("auto next episode");
        //     this.isHudredPersent = false;
        //     this.removeFromContinueWatch(this);
        //   } else {
        //     this.isHudredPersent = false;
        //     this.removeFromContinueWatch(this);
        //     return false;
        //   }
        // } else {
        //   console.log("Close Window");
        //   this.removeFromContinueWatch(this);
        //   this.isHudredPersent = false;
        // }
      }
    });

    this.playermodal.on("seeking", (event) => {

      if (this.playermodal.remainingTime() <= 31) {
        let remainingTime = Math.round(this.playermodal.remainingTime()) + 3 + 's ';
        $('.progress_move_background').css('animation-duration', remainingTime);
      }
    });

    let name = this.title1;
    let type = this.streamInfo.type;
    let code = this.streamInfo.recently_watched.code;
    let action = 'Playing';
    this.playermodal.on("play", (event) => {
      $('.progress_move_background').removeClass("animation-paused");
      // $('.progress_move_background').css('animation-play-state', 'running');
      action = 'Playing';
      this.googleEvent.eventEmitter('users_video', 'users_video', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
      this.googleEvent.eventEmitter('users', 'users', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);


    });

    this.playermodal.on("pause", (event) => {
      // $('.progress_move_background').css('animation-play-state', 'paused');
      $('.progress_move_background').addClass("animation-paused");
      action = 'Paused';

      this.googleEvent.eventEmitter('users_video', 'users_video', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
      this.googleEvent.eventEmitter('users', 'users', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);

      this.stopVideo();
      //this.stopTimerfor10Second();
    });
    this.playermodal.on("fullWindowOnEscKey", (event) => { });

    this.playermodal.on("ended", (event) => {
      console.log("ended");
      action = 'Stopped';
      this.googleEvent.eventEmitter('users_video', 'users_video', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);
      this.googleEvent.eventEmitter('users', 'users', this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + action + "-" + name + "-" + type + "-" + code);

      // mark complet the video
      // this.jwPlayerService.complete('').subscribe((res => { }));
      //this.stopVideo();
    });
    this.playermodal.on("dispose", (event) => {
      this.jwPlayerService.closePlayer.next(true);
    });
    this.playermodal.on("error", (event) => {
      console.error(event);
    });

    //}, 500);
  }

  stopVideo() {
    let cs_uri_query = this.playUrlResObj.result.split("?")[1];
    let cs_uri_stem = this.playUrlResObj.result.split("?")[0];
    let videoLogData = {
      "x-app": this.streamInfo.type == "livetv" ? "livetv" : "vod",
      "x-event": this.action1,
      "sc-bytes": Math.round(this.playermodal.currentTime()),
      "c-user-agent": "platform/web/PlayerVersion-2.7.3",
      "x-duration": this.streamInfo.recently_watched.duration,
      "x-sname": this.currentPlayingName,
      "cs-uri-query": cs_uri_query
        ? encodeURIComponent(cs_uri_query.toString())
        : "",
      "cs-uri-stem": cs_uri_stem,
    };
    setTimeout(() => {
      this.spinner.hide();
    }, 10);

    let urlEncodedEncodeParams =
      this.UserService.convertoJSONToUrlEncoded(videoLogData);
    this.jwPlayerService
      .analyticsLog(urlEncodedEncodeParams)
      .subscribe((res) => { });

    if (this.streamInfo.is_trailer) {
      return
    }

    if (this.streamInfo.is_liveEvenet) {
      return
    }
    console.log(Math.round(this.playermodal.duration()));
    let totalDuration = Math.round(this.playermodal.duration());
    let playedDuration = Math.round(this.playermodal.currentTime());
    let playingDuration = (playedDuration * 100) / totalDuration;
    if (playingDuration <= 98 && playedDuration > 1) {

      let playLogReqdata = Analyticsstreaminglog.logData;
      playLogReqdata.platform = "web";
      playLogReqdata.code = this.currentPlayingCode;
      playLogReqdata.catlogue = CatlougeTypeConstants.catlogue;
      playLogReqdata.play_url = this.videoPlayUrl;
      playLogReqdata.asset_code = this.currentPlayingCode;
      playLogReqdata.category = this.currentPlayingCode;

      playLogReqdata["show_id"] = this.currentPlayingShowID;
      playLogReqdata["season_id"] = this.currentPlayingSeasonId;

      if (this.currentPlayingShowID == "") {
        playLogReqdata["show_id"] = this.streamInfo.recently_watched.show_id;
      }
      if (this.currentPlayingSeasonId == "") {
        playLogReqdata["season_id"] = this.streamInfo.recently_watched.season_id;
      }

      playLogReqdata.stop = Math.round(this.playermodal.currentTime());

      playLogReqdata["duration"] = Math.round(this.playermodal.duration());
      let Playerduration = Math.round(this.playermodal.duration());

      console.log(Playerduration);

      console.log(this.streamInfo);
      playLogReqdata.charge_code = this.currentPlayingCharegCode;
      playLogReqdata["last_video_play_id"] = this.currentPlayingMongoId; //this.streamInfo.asset_mongo_id;
      playLogReqdata["release_date"] = this.streamInfo.release_date
        ? this.streamInfo.release_date
        : "";
      playLogReqdata["name"] = this.currentPlayingName; //this.streamInfo.name ? this.streamInfo.name : this.streamInfo.tvshow_name;
      playLogReqdata["cloud_image"] = this.currentPlayingThumb; //this.streamInfo.thumbnail;
      playLogReqdata["content_availability"] =
        this.currentPlayingContentAvailability; //this.streamInfo.content_availability;
      playLogReqdata["asset_mongo_id"] = this.currentPlayingMongoId;

      playLogReqdata["sec"] = this.currentPlayingSec;
      playLogReqdata["min"] = this.currentPlayingMin;
      playLogReqdata["hours"] = this.currentPlayingHour;
      playLogReqdata["genre_text"] = this.currentPlayingGenreText;
      playLogReqdata["languages"] = this.currentPlayingLanguages;
      playLogReqdata["genre"] = this.currentPlayingGenre;
      playLogReqdata["synopsis"] = this.currentPlayingSys;

      if (
        playLogReqdata["duration"] == undefined ||
        playLogReqdata["duration"] == "undefined"
      ) {
        playLogReqdata["duration"] = this.currentPlayingDuration;
      }

      if (playLogReqdata.stop > playLogReqdata["duration"]) {
        playLogReqdata.stop = playLogReqdata["duration"] - 1;
      }

      console.log("duration " + playLogReqdata["duration"]);

      console.log("playLogReqdata.stop " + playLogReqdata.stop);
      console.log("playLogReqdata.duration " + playLogReqdata["duration"]);

      playLogReqdata.start = 0;
      playLogReqdata.mobile = this.userInfo.result.mobile;

      playLogReqdata["type"] = this.streamInfo.type;
      playLogReqdata["uid"] = this.sessionId;


      setTimeout(() => {
        this.spinner.hide();
      }, 10);
      let urlEncodedPlayData =
        this.UserService.convertoJSONToUrlEncoded(playLogReqdata);

      console.log(urlEncodedPlayData);
      if (!this.is_trailer && !this.is_live_evnet && (playedDuration * 100) / totalDuration <= 100) {

        this.jwPlayerService.pause(urlEncodedPlayData).subscribe((res) => { });
      }
    }

  }

  disableModal() {
    try {
      document.querySelector("ngb-modal-window").classList.remove("show");
      document.querySelector("ngb-modal-backdrop").classList.remove("show");
      document.getElementById("nexgTV-app").click();
      document.querySelectorAll(".modal-backdrop").forEach((el) => el.remove());
      document.querySelectorAll(".d-block").forEach((el) => el.remove());
      document.body.style.overflow = "scroll";
    } catch (e) {
      console.log(e);
    }
  }

  videoLableButtonRander() {
    console.log(this.streamInfo);
    let skip_cast_button = `<button class="skip_cast_button btn btn-primary" id="skip_cast_button" type="button" title="Skip Intro" aria-disabled="false">
    </button>`;
    // let uARating_button = `<button class="uaRationOnPLayer btn btn-primary" id="uaRationOnPLayer" type="button" title="Skip Cast" aria-disabled="false">

    // ${'<div class="rating_underline">' +'RATED' + '</div>' + this.playUrlResObj.assetData.content_rating.viewer_rating
    //   + '<div>' + this.playUrlResObj.assetData.content_rating.content_advisory + '<div>'}
    // </button>`;
    let content_advisory = this.playUrlResObj.assetData.content_rating.content_advisory ?
      this.playUrlResObj.assetData.content_rating.content_advisory
      : '';
    let viewer_rating = this.playUrlResObj.assetData.content_rating.viewer_rating ?
      this.playUrlResObj.assetData.content_rating.viewer_rating
      : '';
    // alert(content_advisory);
    // alert(viewer_rating);

    let uARating_button = `<button class="uaRationOnPLayer btn btn-primary" id="uaRationOnPLayer" type="button" title="Skip Cast" aria-disabled="false">

    ${'<span class="rating_underline">' + 'RATED ' + viewer_rating + '</span>'
      + '<div>' + content_advisory + '<div>'}
    </button>`;

    $(".vjs-big-play-button").after(skip_cast_button);

    $(".vjs-user-inactive").toggleClass('uaRationOnPLayer');


    if (this.playUrlResObj.assetData.content_rating) {
      $(".vjs-big-play-button").after(uARating_button);
      let uiRatingTimeout = this.playUrlResObj.assetData.content_rating.show_duration * 1000;
      setTimeout(() => {
        console.log(uiRatingTimeout);
        $("#uaRationOnPLayer").remove();
      }, uiRatingTimeout);
    }


    $("#skip_cast_button").css({
      background: "rgb(2 2 2 / 100%)",
      width: "130px",
      top: "50%",
      right: "12%",
      cursor: "pointer",
      "z-index": "3",
      position: "absolute",
      display: "none",
    });
  }

  preNextVideo() {
    let totalSeasons = Object.keys(this.streamInfo.seasons).length;

    let seasonArray = [];
    if (totalSeasons > 0) {
      //if (totalSeasons > 1) {
      seasonArray = [];
      for (let i = 0; i < totalSeasons; i++) {
        // add episode number
        if (Object.keys(this.streamInfo.seasons[i].assets).length > 0) {
          for (
            let k = 0;
            k < Object.keys(this.streamInfo.seasons[i].assets).length;
            k++
          ) {
            this.streamInfo.seasons[i].assets[k].episode_number = k + 1;
          }
        }
        seasonArray = [...seasonArray, ...this.streamInfo.seasons[i].assets]; //Object.assign(seasonArray, this.streamInfo.seasons[i].assets);
      }
      this.seasonarray = seasonArray;
      // alert('total length of episodes' +this.seasonarray.length)
      // alert(JSON.stringify(  'list of episodes : '+ this.seasonarray));

      // find code in array index
      let index = seasonArray.findIndex(
        (seasonArray) => seasonArray.code == this.currentPlayingCode
      ); //

      // alert('index'+  index);
      if (seasonArray[index + 1] == undefined) {
        // alert('seasonArray[index + 1]'+  seasonArray[index + 1]);
        this.nextVideoCode = "";
        this.nextVideoName = "";
        this.nextEpisodeNumber = "";
        this.nextSeasonNumber = "";
        this.nextPlayingStartTime = 0;
        this.nextPlayingMongoId = "";
        this.nextPlayingCharegCode = "";
        this.nextPlayingContentAvailability = "";
        this.nextPlayingThumb = "";
        this.nextPlayingSec = "";
        this.nextPlayingMin = "";
        this.nextPlayingHour = "";
        this.nextPlayingGenreText = "";
        this.nextPlayingGenre = "";
        this.nextPlayingSys = "";
        this.nextPlayingLanguages = "";
        this.nextPlayingStop = "";
        this.nextPlayingDuration = "";
        this.nextPlayingShowID = "";
        this.nextPlayingSeasonId = "";
        this.nextVideoDuration = "";
        // this.nextPlayingStreamDetails = { 'charge_code': '', asset_mongo_id: '', release_date: '', name: '', thumbnail: '', content_availability: '', sec: '', min: '', hours: '', genre_name: '', languages: '', genre: '', synopsis: '', season_id:'',show_id:'' };
      } else {
        this.nextVideoCode = seasonArray[index + 1].code;
        // alert(' this.nextVideoCode : --'+  this.nextVideoCode);
        this.nextVideoName = seasonArray[index + 1].name;
        this.nextEpisodeNumber = " E" + seasonArray[index + 1].episode_number;
        this.nextSeasonNumber = " S" + seasonArray[index + 1].season_number;
        this.nextPlayingStartTime = seasonArray[index + 1].seek_bar_data.stop;
        this.nextPlayingMongoId = seasonArray[index + 1].asset_mongo_id;
        this.nextPlayingCharegCode = seasonArray[index + 1].charge_code;
        this.nextPlayingContentAvailability =
          seasonArray[index + 1].content_availability;
        this.nextPlayingThumb = seasonArray[index + 1].thumbnail;

        this.nextPlayingSec = seasonArray[index + 1].sec;
        this.nextPlayingMin = seasonArray[index + 1].min;
        this.nextPlayingHour = seasonArray[index + 1].hour;
        this.nextPlayingGenreText = seasonArray[index + 1].genre_text;
        // this.nextPlayingGenre = seasonArray[index + 1].genre[0];
        this.nextPlayingLanguages = seasonArray[index + 1].languages;
        this.nextPlayingSys = seasonArray[index + 1].synopsis;
        this.nextPlayingStop = seasonArray[index + 1].seek_bar_data.stop;
        this.nextPlayingDuration =
          seasonArray[index + 1].seek_bar_data.duration;

        this.nextPlayingShowID = seasonArray[index + 1].show_id;
        this.nextPlayingSeasonId = seasonArray[index + 1].season_id;

        if (seasonArray[index + 1].hours > 0) {
          if (seasonArray[index + 1].hours < 10) {
            let h = seasonArray[index + 1].min;
            this.nextVideoDuration = h.replace(/^0+/, "") + " h";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].hours + "h";
          }

          if (seasonArray[index + 1].min > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].min +
              "m";
          }
        } else if (
          seasonArray[index + 1].hours <= 0 &&
          seasonArray[index + 1].min > 0
        ) {
          if (seasonArray[index + 1].min < 10) {
            let t = seasonArray[index + 1].min;
            this.nextVideoDuration = t.replace(/^0+/, "") + "m";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].min + "m";
          }

          if (seasonArray[index + 1].sec > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].sec +
              "s";
          }
        } else {
          this.nextVideoDuration =
            this.nextVideoDuration + " " + seasonArray[index + 1].sec + "s";
        }

        //let obj = seasonArray[index + 1];
        // this.nextPlayingStreamDetails = { 'charge_code': obj.charge_code, asset_mongo_id: obj.asset_mongo_id, release_date: obj.release_date, name: obj.name, thumbnail: obj.thumbnail, content_availability: obj.content_availability, sec: obj.sec, min: obj.min, hours: obj.hours, genre_name: obj.genre_text, languages: obj.languages, genre: obj.genre[0], synopsis: obj.synopsis, season_id:obj.season_id,show_id:obj.show_id };
      }
      if (seasonArray[index - 1] == undefined) {
        this.preVideoCode = "";
        this.preVideoName = "";
        this.preEpisodeNumber = "";
        this.preSeasonNumber = "";
        this.prePlayingStartTime = 0;
        this.prePlayingThumb = "";
        this.preVideoDuration = "";
        // this.prePlayingStreamDetails = { 'charge_code': '', asset_mongo_id: '', release_date: '', name: '', thumbnail: '', content_availability: '', sec: '', min: '', hours: '', genre_name: '', languages: '', genre: '', synopsis: '', season_id:'',show_id:'' };
      } else {
        this.preVideoCode = seasonArray[index - 1].code;
        this.preVideoName = seasonArray[index - 1].name;
        this.preEpisodeNumber = " E" + seasonArray[index - 1].episode_number;
        this.preSeasonNumber = " S" + seasonArray[index - 1].season_number;
        this.prePlayingStartTime = seasonArray[index - 1].seek_bar_data.stop;
        this.prePlayingThumb = seasonArray[index - 1].thumbnail;

        if (seasonArray[index - 1].hours > 0) {
          if (seasonArray[index - 1].hours < 10) {
            let h = seasonArray[index - 1].hours;
            this.preVideoDuration = h.replace(/^0+/, "") + "h";
          } else {
            this.preVideoDuration = seasonArray[index - 1].hours + "h";
          }

          if (seasonArray[index - 1].min > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].min + "m";
          }
        } else if (
          seasonArray[index - 1].hours <= 0 &&
          seasonArray[index - 1].min > 0
        ) {
          if (seasonArray[index - 1].min < 10) {
            let m = seasonArray[index - 1].min;
            this.preVideoDuration = m.replace(/^0+/, "") + "m";
          } else {
            this.preVideoDuration = seasonArray[index - 1].min + "m";
          }

          if (seasonArray[index - 1].sec > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
          }
        } else {
          this.preVideoDuration =
            this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
        }
        //this.prePlayingStreamDetails = { 'charge_code': obj.charge_code, asset_mongo_id: obj.asset_mongo_id, release_date: obj.release_date, name: obj.name, thumbnail: obj.thumbnail, content_availability: obj.content_availability, sec: obj.sec, min: obj.min, hours: obj.hours, genre_name: obj.genre_text, languages: obj.languages, genre: obj.genre[0], synopsis: obj.synopsis , season_id:obj.season_id,show_id:obj.show_id};
      }
    }
    this.lastEpisode = seasonArray[seasonArray.length - 1];
  }


  trailerPreviewList() {

    console.log('this.streamInfo.trailer', this.streamInfo);
    this.streamInfo.trailer = [];
    console.log('this.streamInfo', this.streamInfo);
    let object_detail = this.streamInfo;
    this.streamInfo.new_trailer.forEach((element, i) => {
      this.streamInfo.trailer.push(this.streamInfo.new_trailer[i]);
      this.streamInfo.trailer[i].recently_watched = this.streamInfo.recently_watched;
      this.streamInfo.trailer[i].asset_mongo_id = this.streamInfo.asset_mongo_id;
      this.streamInfo.trailer[i].charge_code = this.streamInfo.charge_code;
      this.streamInfo.trailer[i].code = this.streamInfo.code;
      this.streamInfo.trailer[i].content_availability = this.streamInfo.content_availability;
      this.streamInfo.trailer[i].genre_text = this.streamInfo.genre_text;
      this.streamInfo.trailer[i].sec = this.streamInfo.sec;
      this.streamInfo.trailer[i].min = this.streamInfo.min;
      this.streamInfo.trailer[i].hours = this.streamInfo.hours;
      this.streamInfo.trailer[i].synopsis = this.streamInfo.synopsis;
      this.streamInfo.trailer[i].languages = this.streamInfo.languages;
      // this.streamInfo.trailer.push(this.streamInfo.object_detail);


    });
    // this.streamInfo.trailer.push(this.streamInfo);
    console.log('this.streamInfo.trailer', this.streamInfo.trailer);

    let totalSeasons = Object.keys(this.streamInfo.trailer).length;
    // let totalSeasons = 1;
    console.log('totalSeasons', totalSeasons);
    // debugger;

    console.log('totalSeasons', totalSeasons);
    console.log('this.streamInfo.trailer', this.streamInfo.trailer);
    console.log('this.streamInfo', this.streamInfo);
    // debugger;
    // alert('total season' +totalSeasons )
    let seasonArray = [];
    if (totalSeasons > 0) {
      //if (totalSeasons > 1) {
      seasonArray = [];
      for (let i = 0; i < totalSeasons; i++) {
        // add episode number
        if (this.streamInfo.trailer && Object.keys(this.streamInfo.trailer).length > 0) {
          for (
            let k = 0;
            k < Object.keys(this.streamInfo.trailer).length;
            k++
          ) {
            this.streamInfo.trailer[k].episode_number = k + 1;
          }
        }
        seasonArray = [...seasonArray, ...this.streamInfo.trailer]; //Object.assign(seasonArray, this.streamInfo.trailer[i].assets);
      }
      this.seasonarray = seasonArray;
      // alert('total length of episodes' +this.seasonarray.length)
      // alert(JSON.stringify(  'list of episodes : '+ this.seasonarray));

      // find code in array index
      let index = seasonArray.findIndex(
        (seasonArray) => seasonArray.code == this.currentPlayingCode
      ); //

      console.log('index', index);
      console.log('this.currentPlayingCode', this.currentPlayingCode);
      console.log('seasonArray', seasonArray);


      // debugger;
      if (seasonArray[index + 1] == undefined) {
        // alert('seasonArray[index + 1]'+  seasonArray[index + 1]);
        this.nextVideoCode = "";
        this.nextVideoName = "";
        this.nextEpisodeNumber = "";
        this.nextSeasonNumber = "";
        this.nextPlayingStartTime = 0;
        this.nextPlayingMongoId = "";
        this.nextPlayingCharegCode = "";
        this.nextPlayingContentAvailability = "";
        this.nextPlayingThumb = "";
        this.nextPlayingSec = "";
        this.nextPlayingMin = "";
        this.nextPlayingHour = "";
        this.nextPlayingGenreText = "";
        this.nextPlayingGenre = "";
        this.nextPlayingSys = "";
        this.nextPlayingLanguages = "";
        this.nextPlayingStop = "";
        this.nextPlayingDuration = "";
        this.nextPlayingShowID = "";
        this.nextPlayingSeasonId = "";
        this.nextVideoDuration = "";
        // this.nextPlayingStreamDetails = { 'charge_code': '', asset_mongo_id: '', release_date: '', name: '', thumbnail: '', content_availability: '', sec: '', min: '', hours: '', genre_name: '', languages: '', genre: '', synopsis: '', season_id:'',show_id:'' };
      } else {
        this.nextVideoCode = seasonArray[index + 1].code;
        // alert(' this.nextVideoCode : --'+  this.nextVideoCode);
        this.nextVideoName = seasonArray[index + 1].name;
        this.nextEpisodeNumber = " E" + seasonArray[index + 1].episode_number;
        this.nextSeasonNumber = " S" + seasonArray[index + 1].season_number;
        this.nextPlayingStartTime = seasonArray[index + 1].recently_watched.stop;
        this.nextPlayingMongoId = seasonArray[index + 1].asset_mongo_id;
        this.nextPlayingCharegCode = seasonArray[index + 1].charge_code;
        this.nextPlayingContentAvailability =
          seasonArray[index + 1].content_availability;
        this.nextPlayingThumb = seasonArray[index + 1].thumbnail;

        this.nextPlayingSec = seasonArray[index + 1].sec;
        this.nextPlayingMin = seasonArray[index + 1].min;
        this.nextPlayingHour = seasonArray[index + 1].hour;
        this.nextPlayingGenreText = seasonArray[index + 1].genre_text;
        // this.nextPlayingGenre = seasonArray[index + 1].genre[0];
        this.nextPlayingLanguages = seasonArray[index + 1].languages;
        this.nextPlayingSys = seasonArray[index + 1].synopsis;
        this.nextPlayingStop = seasonArray[index + 1].recently_watched.stop;
        this.nextPlayingDuration =
          seasonArray[index + 1].recently_watched.duration;

        this.nextPlayingShowID = seasonArray[index + 1].show_id;
        this.nextPlayingSeasonId = seasonArray[index + 1].season_id;

        if (seasonArray[index + 1].hours > 0) {
          if (seasonArray[index + 1].hours < 10) {
            let h = seasonArray[index + 1].min;
            this.nextVideoDuration = h.replace(/^0+/, "") + " h";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].hours + "h";
          }

          if (seasonArray[index + 1].min > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].min +
              "m";
          }
        } else if (
          seasonArray[index + 1].hours <= 0 &&
          seasonArray[index + 1].min > 0
        ) {
          if (seasonArray[index + 1].min < 10) {
            let t = seasonArray[index + 1].min;
            this.nextVideoDuration = t.replace(/^0+/, "") + "m";
          } else {
            this.nextVideoDuration = seasonArray[index + 1].min + "m";
          }

          if (seasonArray[index + 1].sec > 0) {
            this.nextVideoDuration =
              this.nextVideoDuration +
              " " +
              seasonArray[index + 1].sec +
              "s";
          }
        } else {
          this.nextVideoDuration =
            this.nextVideoDuration + " " + seasonArray[index + 1].sec + "s";
        }

        //let obj = seasonArray[index + 1];
        // this.nextPlayingStreamDetails = { 'charge_code': obj.charge_code, asset_mongo_id: obj.asset_mongo_id, release_date: obj.release_date, name: obj.name, thumbnail: obj.thumbnail, content_availability: obj.content_availability, sec: obj.sec, min: obj.min, hours: obj.hours, genre_name: obj.genre_text, languages: obj.languages, genre: obj.genre[0], synopsis: obj.synopsis, season_id:obj.season_id,show_id:obj.show_id };
      }
      if (seasonArray[index - 1] == undefined) {
        this.preVideoCode = "";
        this.preVideoName = "";
        this.preEpisodeNumber = "";
        this.preSeasonNumber = "";
        this.prePlayingStartTime = 0;
        this.prePlayingThumb = "";
        this.preVideoDuration = "";
        // this.prePlayingStreamDetails = { 'charge_code': '', asset_mongo_id: '', release_date: '', name: '', thumbnail: '', content_availability: '', sec: '', min: '', hours: '', genre_name: '', languages: '', genre: '', synopsis: '', season_id:'',show_id:'' };
      } else {
        this.preVideoCode = seasonArray[index - 1].code;
        this.preVideoName = seasonArray[index - 1].name;
        this.preEpisodeNumber = " E" + seasonArray[index - 1].episode_number;
        this.preSeasonNumber = " S" + seasonArray[index - 1].season_number;
        this.prePlayingStartTime = seasonArray[index - 1].recently_watched.stop;
        this.prePlayingThumb = seasonArray[index - 1].thumbnail;

        if (seasonArray[index - 1].hours > 0) {
          if (seasonArray[index - 1].hours < 10) {
            let h = seasonArray[index - 1].hours;
            this.preVideoDuration = h.replace(/^0+/, "") + "h";
          } else {
            this.preVideoDuration = seasonArray[index - 1].hours + "h";
          }

          if (seasonArray[index - 1].min > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].min + "m";
          }
        } else if (
          seasonArray[index - 1].hours <= 0 &&
          seasonArray[index - 1].min > 0
        ) {
          if (seasonArray[index - 1].min < 10) {
            let m = seasonArray[index - 1].min;
            this.preVideoDuration = m.replace(/^0+/, "") + "m";
          } else {
            this.preVideoDuration = seasonArray[index - 1].min + "m";
          }

          if (seasonArray[index - 1].sec > 0) {
            this.preVideoDuration =
              this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
          }
        } else {
          this.preVideoDuration =
            this.preVideoDuration + " " + seasonArray[index - 1].sec + "s";
        }
        //this.prePlayingStreamDetails = { 'charge_code': obj.charge_code, asset_mongo_id: obj.asset_mongo_id, release_date: obj.release_date, name: obj.name, thumbnail: obj.thumbnail, content_availability: obj.content_availability, sec: obj.sec, min: obj.min, hours: obj.hours, genre_name: obj.genre_text, languages: obj.languages, genre: obj.genre[0], synopsis: obj.synopsis , season_id:obj.season_id,show_id:obj.show_id};
      }
    }
    this.lastEpisode = seasonArray[seasonArray.length - 1];
    // debugger;
  }

  startTimerFor10Second() {
    this.timerForPlayer = 0;
    clearInterval(this.timerOfPlayer);
    // this.timerOfPlayer = setInterval(()=>{
    //     // hit the Video End APi
    //     //this.stopVideo();
    //     this.timerForPlayer++;
    //     console.log(this.timerForPlayer);
    // }, 10000);
  }

  stopTimerfor10Second() {
    this.timerForPlayer = 0;
    clearInterval(this.timerOfPlayer);
  }

  sendNextEpisode(nextVideObj) { }

  removeFromContinueWatch(episodeObj) {
    if (this.streamInfo.is_trailer) {
      return
    }
    //if assets is movie then hit the remove continue watching api only is movie case
    //if asset is episode then only need to hit remove continue waching api else
    // id : tv_show_id, assets_id,
    // type: episode, vod
    // Get Next Episode and send it to the API.
    // In Every Episode Episode code is coming inside seek_bar_data
    let deleteConObj = {};
    deleteConObj["id"] = this.streamInfo.show_id
      ? this.streamInfo.show_id
      : this.currentPlayingMongoId; // this.streamInfo.asset_mongo_id,
    deleteConObj["type"] = this.videoType == "episode" ? "episode" : "vod";
    deleteConObj["catlogue"] = CatlougeTypeConstants.catlogue;
    deleteConObj["action"] = "delete";
    let urlEncodedeleteObj =
      this.UserService.convertoJSONToUrlEncoded(deleteConObj);

    if (this.videoType == "episode") {
      if (this.nextVideoCode == undefined) {
        setTimeout(() => {
          this.spinner.hide();
        }, 50);
        this.jwPlayerService
          .removeFromContinueWatching(urlEncodedeleteObj)
          .subscribe((res) => {
            console.log(`current playing series cleared`);
          });
      } else {
        // if (episodeObj.nextVideObj != undefined) {

        console.log('video End api call for set SeekBar of current Playing');

        let playLogReqdata = Analyticsstreaminglog.logData;
        // let newNextEpisodeObj = {
        //   ...playLogReqdata,
        //   ...episodeObj.nextVideObj,
        // };
        // delete newNextEpisodeObj.download_url;
        // delete newNextEpisodeObj.seek_bar_data;
        // delete newNextEpisodeObj.genre_text;
        // delete newNextEpisodeObj.type_id;
        // delete newNextEpisodeObj._id;
        playLogReqdata["duration"] = this.currentPlayingDuration;
        playLogReqdata["last_video_play_id"] = this.currentPlayingMongoId;
        playLogReqdata["asset_mongo_id"] = this.currentPlayingMongoId;
        //newNextEpisodeObj.asset_cat_type = this.videoType;
        playLogReqdata["uid"] = this.sessionId;
        playLogReqdata.type = this.videoType == "episode" ? "episode" : "vod";
        playLogReqdata.catlogue = CatlougeTypeConstants.catlogue;
        playLogReqdata.asset_code = this.currentPlayingCode;
        playLogReqdata.charge_code = this.currentPlayingCharegCode;
        playLogReqdata.paid_status = this.currentPlayingContentAvailability;
        playLogReqdata["content_availability"] = this.currentPlayingContentAvailability;
        playLogReqdata.code = this.currentPlayingCode;
        playLogReqdata.play_url = this.videoPlayUrl;
        playLogReqdata.category = this.currentPlayingCode;
        playLogReqdata.release_date = "";
        playLogReqdata.name = this.title;
        playLogReqdata["cloud_image"] = this.currentPlayingThumb;
        playLogReqdata["sec"] = this.currentPlayingSec;
        playLogReqdata["min"] = this.currentPlayingMin;
        playLogReqdata["hours"] = this.currentPlayingHour;
        playLogReqdata["genre_text"] = this.currentPlayingGenreText;
        playLogReqdata["languages"] = this.currentPlayingLanguages;
        playLogReqdata.genre = this.currentPlayingGenre;
        playLogReqdata["synopsis"] = this.currentPlayingSys;
        playLogReqdata.stop = parseInt(this.currentPlayingDuration);
        playLogReqdata["show_id"] = this.currentPlayingShowID;
        playLogReqdata["season_id"] = this.currentPlayingSeasonId;

        console.log(playLogReqdata);
        let urlEncodedNextObj =
          this.UserService.convertoJSONToUrlEncoded(playLogReqdata);
        setTimeout(() => {
          this.spinner.hide();
        }, 50);
        this.jwPlayerService.pause(urlEncodedNextObj).subscribe((res) => {
          // if (this.nextPlayingMongoId != undefined) {

          // console.log('nextPlayingMongoId');
          // newNextEpisodeObj["last_video_play_id"] = this.nextPlayingMongoId;
          // newNextEpisodeObj["asset_mongo_id"] = this.nextPlayingMongoId;
          // newNextEpisodeObj.asset_code = this.nextVideoCode;
          // newNextEpisodeObj.paid_status =
          //   this.nextPlayingContentAvailability;
          // newNextEpisodeObj.content_availability =
          //   this.nextPlayingContentAvailability;
          // newNextEpisodeObj.charge_code = this.nextPlayingCharegCode;

          // newNextEpisodeObj.code = this.nextVideoCode;
          // newNextEpisodeObj.play_url = "";
          // newNextEpisodeObj.category = this.nextVideoCode;
          // newNextEpisodeObj.release_date = "";
          // newNextEpisodeObj.name = this.nextVideoName;
          // newNextEpisodeObj.cloud_image = this.nextPlayingThumb;
          // newNextEpisodeObj.sec = this.nextPlayingSec;
          // newNextEpisodeObj.min = this.nextPlayingMin;
          // newNextEpisodeObj.hours = "00";
          // newNextEpisodeObj.genre_text = this.nextPlayingGenreText;
          // newNextEpisodeObj.languages = this.nextPlayingLanguages;
          // newNextEpisodeObj.genre = this.nextPlayingGenreText;
          // newNextEpisodeObj.synopsis = this.nextPlayingSys;
          // newNextEpisodeObj.stop = this.nextPlayingStop;
          // newNextEpisodeObj.duration = this.nextPlayingDuration;
          // newNextEpisodeObj.show_id = this.nextPlayingShowID;
          // newNextEpisodeObj.season_id = this.nextPlayingSeasonId;

          // this.currentPlayingShowID = this.nextPlayingShowID;
          // this.currentPlayingSeasonId = this.nextPlayingSeasonId;

          // let urlEncodedNextObjNew =
          //   this.UserService.convertoJSONToUrlEncoded(newNextEpisodeObj);
          // setTimeout(() => {
          //   this.spinner.hide();
          // }, 50);
          // this.jwPlayerService
          //   .pause(urlEncodedNextObjNew)
          //   .subscribe((res) => {
          //     console.log('done')
          //   });
          // }
        });
        // }
      }
    } else {
      setTimeout(() => {
        this.spinner.hide();
      }, 50);
      this.jwPlayerService
        .removeFromContinueWatching(urlEncodedeleteObj)
        .subscribe((res) => {
          console.log("content remove");
        });
    }
  }

  nextButtonAction(param: any) {
    let currentTime = this.nextPlayingStartTime;
    this.nextVideoDuration = '';
    this.preVideoDuration = '';
    console.log(this.nextVideoCode)
    this.getPlayUrl(this.nextVideoCode)
      .then((playObj) => {
        this.playType = "next";
        this.playUrlResObj = playObj;
        var sources = [
          { type: "application/x-mpegURL", src: this.videoPlayUrl },
        ];


        let advertisement_url = playObj.assetData.advertisement_url;
        this.playermodal.ima.setContentWithAdTag(sources, advertisement_url);
        this.playermodal.ima.requestAds();

        this.isHudredPersent = true;
        this.watch_credit_status = 1;
      })
      .catch((error) => {
        console.log("something went wrong to access the next video");
        console.log(error);
      });
  }
  backButtonAction(param: any) {
    //this.stopVideo();

    let preTime = this.prePlayingStartTime;
    this.getPlayUrl(this.preVideoCode)
      .then((playObj) => {
        this.playType = "pre";
        var sources = [
          { type: "application/x-mpegURL", src: this.videoPlayUrl },
        ];

        let advertisement_url = playObj.assetData.advertisement_url;
        this.playermodal.ima.setContentWithAdTag(sources, advertisement_url);
        this.playermodal.ima.requestAds();
        let name = this.title1;
        let type = this.streamInfo.type;
        let code = this.streamInfo.recently_watched.code;
        let timeInSeconds = this.playermodal.currentTime();
        // let action='Stopped';
        // this.googleEvent.eventEmitter('users_video', 'users_video', this.userUnique+"-"+action+"-"+name+"-"+type+"-"+code, 0, this.userUnique, this.userUnique+"-"+action+"-"+name+"-"+type+"-"+code);
        // this.googleEvent.eventEmitter('users', 'users', this.userUnique+"-"+action+"-"+name+"-"+type+"-"+code, 0, this.userUnique, this.userUnique+"-"+action+"-"+name+"-"+type+"-"+code);
        this.googleEvent.eventEmitter('video_viewtime', 'video_viewtime', this.userUnique + "-" + timeInSeconds + "s-" + name + "-" + type + "-" + code, 0, this.userUnique, this.userUnique + "-" + timeInSeconds + "s-" + name + "-" + type + "-" + code);

        // this.playermodal.src(sources);
        // this.playermodal.load();
        // this.playermodal.play();

        // this.playermodal.currentTime(this.prePlayingStartTime);
        // var _this = this;
        // this.playermodal.one('loadedmetadata', function () {
        //   console.log('setting current time to 60 seconds');
        //   _this.playermodal.currentTime(0);
        // });
      })
      .catch((error) => {
        console.log("something went wrong to access the next video");
      });
  }
  skipIntroButtonAction() {
    this.playermodal.currentTime(this.playUrlResObj.assetData.skip_intro.end);
  }

  videoLabelButtonAction(videoLabelObj) {
    if (videoLabelObj[0].is_watch_credit == "1") {
      this.watch_credit_status = 0;
      // this.videoLabelObj[0].is_watch_credit = 0;
      $(".skip_cast_button").css({ display: "none" });
      // $("#next_button").remove();
      // $("#back_button").remove();
      console.log(videoLabelObj);
    } else {
      this.playermodal.currentTime(videoLabelObj[0].end);
    }
  }

  getPlayUrl(code): Promise<any> {
    return new Promise((resolve, reject) => {
      this.rmContApiCalledStatus = false;
      let videoStreamObj = {
        nmode: "WIFI",
        pushId: "",
        ct: "1",
        build: 10000,
        charge_code: code,
        appv: "1.0.00",
        push_date: "",
        air: "airip",
      };
      let urlEncodedStreamInfo =
        this.UserService.convertoJSONToUrlEncoded(videoStreamObj);
      console.log(urlEncodedStreamInfo);
      this.VideoPlayerService.playUrl(urlEncodedStreamInfo, 0, 0).subscribe(
        (playObj) => {
          console.log(playObj);
          if (playObj.result == undefined || playObj.result == "") {
            this.playermodal.pause();
            this.nextEpisodeIsBlank = false;
            if (this.nextVideoPaidData.content_availability == "PAID") {
              this.currentPlayingCode = code;
              this.currentPlayingMongoId = this.nextVideoPaidData.asset_mongo_id;
              this.currentPlayingCharegCode = this.nextVideoPaidData.charge_code;
              this.currentPlayingContentAvailability = this.nextVideoPaidData.content_availability;
              this.currentPlayingThumb = this.nextVideoPaidData.thumbnail;
              this.currentPlayingName = this.nextVideoPaidData.name;

              this.currentPlayingSec = this.nextVideoPaidData.sec;
              this.currentPlayingMin = this.nextVideoPaidData.min;
              let tempminsc = parseInt(this.nextVideoPaidData.min) * 60;
              let temhourtosec = (parseInt(this.nextVideoPaidData.hours) * 60) * 60;
              let totalDuration = parseInt(this.currentPlayingSec) + tempminsc + temhourtosec;
              this.currentPlayingHour = this.nextVideoPaidData.hours;
              this.currentPlayingLanguages = this.currentPlayingData.languages;
              this.currentPlayingSys = this.currentPlayingData.synopsis;
              this.currentPlayingShowID = this.currentPlayingData.show_id;
              this.currentPlayingSeasonId = this.currentPlayingData.season_id;

              let playLogReqdata = Analyticsstreaminglog.logData;
              playLogReqdata.platform = "web";
              playLogReqdata.code = this.currentPlayingCode;
              playLogReqdata.catlogue = CatlougeTypeConstants.catlogue;
              playLogReqdata.play_url = this.videoPlayUrl;
              playLogReqdata.asset_code = this.currentPlayingCode;
              playLogReqdata.category = this.currentPlayingCode;
              playLogReqdata["show_id"] = this.currentPlayingShowID;
              playLogReqdata["season_id"] = this.currentPlayingSeasonId;
              playLogReqdata.charge_code = this.currentPlayingCharegCode;
              playLogReqdata["last_video_play_id"] = this.currentLastPlayingSeasonId; //this.streamInfo.asset_mongo_id;
              playLogReqdata["release_date"] = this.streamInfo.release_date ? this.streamInfo.release_date : "";
              playLogReqdata["name"] = this.currentPlayingName; //this.streamInfo.name ? this.streamInfo.name : this.streamInfo.tvshow_name;
              playLogReqdata["cloud_image"] = this.currentPlayingThumb; //this.streamInfo.thumbnail;
              playLogReqdata["content_availability"] = this.currentPlayingContentAvailability; //this.streamInfo.content_availability;
              playLogReqdata["asset_mongo_id"] = this.currentPlayingMongoId;
              playLogReqdata["sec"] = this.currentPlayingSec;
              playLogReqdata["min"] = this.currentPlayingMin;
              playLogReqdata["hours"] = this.currentPlayingHour;
              playLogReqdata["genre_text"] = this.currentPlayingGenreText;
              playLogReqdata["languages"] = this.currentPlayingLanguages;
              playLogReqdata["genre"] = this.currentPlayingGenre;
              playLogReqdata["synopsis"] = this.currentPlayingSys;
              playLogReqdata.start = 0;
              playLogReqdata.stop = 5;
              playLogReqdata["duration"] = totalDuration;
              playLogReqdata.mobile = this.userInfo.result.mobile;
              playLogReqdata["type"] = this.streamInfo.type;
              playLogReqdata["uid"] = this.sessionId;
              let urlEncodedPlayData = this.UserService.convertoJSONToUrlEncoded(playLogReqdata);
              this.jwPlayerService.pause(urlEncodedPlayData).subscribe((res) => { });
            }
            this.error = playObj.error_string;
            this.showErrorMsg.nativeElement.click();
            // setTimeout(() => {
            //   this.disableModal();
            // if (this.playermodal) this.playermodal.dispose();
            // }, 1000);

            reject();
          } else {
            this.nextVideoPaidData = playObj.next_episode;
            this.playUrlResObj = playObj;
            this.nextVideoCode = playObj.next_episode.code;
            this.currentPlayingData = playObj.assetData;
            if (
              playObj.cloud_front &&
              Object.keys(playObj.cloud_front).length > 0
            ) {
              this.videoPlayUrl = playObj.cloud_front.cloud_url;
              console.log(this.playUrlResObj);
              this.setCookie(
                "CloudFront-Key-Pair-Id",
                playObj.cloud_front.cloudfront_key_pair_id.trim(),
                1
              );
              this.setCookie(
                "CloudFront-Policy",
                playObj.cloud_front.cloudfront_policy.trim(),
                1
              );
              this.setCookie(
                "CloudFront-Signature",
                playObj.cloud_front.cloudfront_signature.trim(),
                1
              );
            } else {
              this.videoPlayUrl = playObj.result;
            }



            if (playObj.assetData.type == 'episode' || playObj.assetData.type == 'tvshow') {
              let seasonN = this.streamInfo.totalSeason > 1 ? 'S' + playObj.assetData.show_details.season_number : '';
              this.title = '<div id="show_temp">' +
                '<div class="temp-title">' + playObj.assetData.show_details.show_title + '</div>' +
                '<span class="temp-seasnon">' + seasonN + ' E' + playObj.assetData.show_details.episode_number + ' | ' + '</span>' +
                '<span class="temp-seasnon">' + playObj.assetData.show_details.episode_title + '</span>' + '</div>';

              this.title1 = playObj.assetData.show_details.show_title + 'S' + playObj.assetData.show_details.season_number + ' E' + playObj.assetData.show_details.episode_number + ' | ' +
                + playObj.assetData.show_details.episode_title;

            } else {
              this.title = '<div id="show_temp">' +
                '<span class="temp-seasnon movie_title">' + playObj.assetData.name + '</span>' + '</div>';

              this.title1 = (playObj.assetData.name || '');
            }


            $(".vjs-ttl").html(this.title);

            this.currentPlayingCode = code;
            this.playermodal.pause();
            this.playermodal.poster(playObj.assetData.image);

            if (playObj.assetData.trick_play && playObj.assetData.trick_play.image_url) {
              let img_interval = playObj.assetData.trick_play.size;
              let img_width = playObj.assetData.trick_play.width && playObj.assetData.trick_play.width != "" ? playObj.assetData.trick_play.width : 150;
              let img_height = playObj.assetData.trick_play.height && playObj.assetData.trick_play.height != "" ? playObj.assetData.trick_play.height : 84;
              invokeSpriteScript(playObj.assetData.trick_play.image_url, img_interval, img_width, img_height);
            }
            this.currentPlayingMongoId = this.currentPlayingData._id;
            this.currentPlayingCharegCode = this.currentPlayingData.charge_code;
            this.currentPlayingContentAvailability = this.currentPlayingData.content_availability;
            this.currentPlayingThumb = this.currentPlayingData.image;
            this.currentPlayingName = this.currentPlayingData.name;

            this.currentPlayingSec = this.currentPlayingData.sec;
            this.currentPlayingMin = this.currentPlayingData.min;
            this.currentPlayingHour = this.currentPlayingData.hours;
            this.currentPlayingDuration = this.currentPlayingData.duration;
            this.currentPlayingGenreText = this.currentPlayingData.genre_text;
            this.currentPlayingLanguages = this.currentPlayingData.languages;
            this.currentPlayingSys = this.currentPlayingData.synopsis;
            this.currentPlayingShowID = this.currentPlayingData.show_id;
            this.currentPlayingSeasonId = this.currentPlayingData.season_id;
            this.currentLastPlayingSeasonId = this.playUrlResObj.last_video_play_id;
            // this.preNextVideo();
            resolve(playObj);
          }
        }
      );
    });
  }

  removeErrorBox() {
    if (this.playermodal != "") {
      console.log("removeErrorBox");
      this.playermodal.currentTime(0);
      console.log(this.playermodal.currentTime());
      this.stopVideo();
    }

    this.closeShowErrorMsg.nativeElement.click();
    setTimeout(() => {
      this.disableModal();
      if (this.playermodal) this.playermodal.dispose();

      //$('body').removeClass('vjs-full-window');
      this.playermodal = "";
    }, 50);
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }


  deleteCookie(cname, cvalue) {
    var expires = "expires=" + "Thu, 01 Jan 1970 00:00:00 UTC";
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

}
