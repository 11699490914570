<div class="maincontentarea mt20">
   <div class="container">
      <div class="terms">
         <div class="row">
            <div class="col-sm-12">
               <!-- <h1 class="_dummy_t">There is no content</h1> -->
               <div class="_contact_content">
                  <h2>Contact Us</h2>
                  <!-- <div class="_contact_map">
                   <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3505.847363202616!2d77.0780792150484!3d28.514239696175917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d18691f0ea3bf%3A0xbc987bd64c947b88!2snexGTv-Digivive%20Services%20Pvt.%20Ltd.%7C%20India&#39;s%20Leading%20OTT%20Platform!5e0!3m2!1sen!2sin!4v1610702432059!5m2!1sen!2sin" width="100%" height="300" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                 </div> -->
                  <div class="_contact_split">
                     <div class="info_text">
                        <h1>We'd love to hear from you!</h1>
                        <P>For any information or queries please find contact detail.</P>
                     </div>
                     <div class="_nexgtv_detail">
                        <h3>Digivive Services Pvt Ltd</h3>
                        <ul>
                           <!--  <li>
                              <i class="fa fa-map-marker" aria-hidden="true"></i>
                              <p>Plot No 45-46, Udyog Vihar Phase - 1,
                              <br>Gurgaon - 122016, Haryana, India.</p>
                           </li>
                           <li>
                              <i class="fa fa-phone" aria-hidden="true"></i>
                              <a href="tel:+918582831888">+91-8582831888</a>
                           </li> -->
                           <li>
                              <i class="fa fa-envelope" aria-hidden="true"></i>
                              <a href="mailto:customercare@digivive.com">customercare@digivive.com</a>
                           </li>
                        </ul>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</div>