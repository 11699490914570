<section class="slider_img" *ngIf="bannerResult">
    <div class="inner_img">
        <div class="owl-carousel banner_slider owl-theme">
            <owl-carousel-o [options]="owlOption">
                <ng-container *ngFor="let item of bannerResult;  let i=index">
                    <ng-template carouselSlide class="item">
                        <div id="detail_banner" class="_slider_c bottom-bg-layer">
                            <!-- <img src="{{item.image}}"> -->
                            <!-- <img [style]="{ 'display': is_preview[i]==true ? 'none' : 'block'}" src="https://stage-image.nexgtv.com/eyJidWNrZXQiOiJkaWdpLWZpbGUtaW1nIiwia2V5IjoibmV4Z3R2L2ltZy9tb3ZpZS9NT1Y0OTcxNDI0OTYvNjRkZjE3YjkyNzI2Zi01NDM5Ni5wbmciLCJlZGl0cyI6eyJub3JtYWxpemUiOmZhbHNlLCJncmF5c2NhbGUiOmZhbHNlLCJ3ZWJwIjp0cnVlLCJzaGFycGVuIjpmYWxzZX19"> -->
                            <!-- [style]="{ 'display': is_preview[i]==true ? 'block' : 'none'}" -->
                            <!-- [style]="{ 'display': showfewSecondImage[i]==true ? 'none' : 'block'}" -->
                            <!-- <img alt=""   src="{{item.image_cloudfront_url}}" [class]="showfewSecondImage[i]==true ? 'forshowandhide' : '' " [style]="{ 'display': showfewSecondImage[i]==true ? 'none' : 'block'}"> -->
                            <!-- [class]="addBefourVideo1Second[i]==true ? 'forshowandhide' : ''"  -->
                            <img alt=""  class="bannervimg" [class]="addBefourVideo1Second[i]==true ? 'forshowandhide' : ''"   src="{{item.image_cloudfront_url}}" [style]="{ 'z-index': showfewSecondImage[i]==true ? '0' : '1'}"   >
                            <!-- [style]="{ 'display': showfewSecondImage[i]==true ? 'block' : 'none'}" -->
                            <div class="trailer_video" id="trailer_video">
                                <video id="bannervideo{{i}}" class="video-js vjs-fluid  vjs-16-9" controls autoplay
                                    preload="auto" playsinline>
                                    <p class="vjs-no-js">
                                        To view this video please enable JavaScript, and consider upgrading to a
                                        web browser that
                                        <a href="https://videojs.com/html5-video-support/" target="_blank">supports
                                            HTML5 video</a>
                                    </p>
                                </video>
                            </div>

                            <div class="video_banner_layer">
                                <div class="container">
                                    <div class="row">
                                        <div class="col-md-12 mt_video">
                                            <img  *ngIf="item.image_transparent_cloudfront_url!=''" alt="bannerImage" class="video_img"  src="{{item.image_transparent_cloudfront_url}}" />
                                            <div class="_s_play_btn" *ngIf="!item.data_type">
                                                <a class="active_btn"
                                                    (click)="playVideo(item, content, loginContent)">Watch Now
                                                    <img alt="bannerWatchNowImage" src="assets/img/Watch_Now.png"></a>
                                                <span class="video_banner_rating" *ngIf="item.viewer_rating.rating_name!='' && item.viewer_rating.rating_name!=undefined">{{item.viewer_rating.rating_name}}</span>
                                                <i class="banner_mute_button" [style]="{ 'display': showfewSecondImage[i]==true ? 'block' : 'none'}">
                                                    <img alt=""  [src]="is_muted ? 'assets/img/mute.png' : 'assets/img/unmute.png'" (click)="!is_muted ? muteUnmutePreview(0,i) : muteUnmutePreview(1,i)">
                                                </i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>

</section>


<a data-toggle="modal" data-target="#addToWatchListfrombanner" #addToWatchListSuccessFromBanner></a>
<div class="modal auth" id="addToWatchListfrombanner" *ngIf="userInfo">
    <div class="modal-dialog modal-dialog-centered modal-dialog-zoom">
        <div class="modal-content">

            <!-- Modal Header -->
            <div class="modal-header" style="visibility: hidden;">
                <button type="button" id="closeAddToWatchListInBanner" class="close"
                    data-dismiss="modal">&times;</button>
            </div>

            <!-- Modal body -->
            <div class="modal-body">
                <div class="success-add">
                    <img src="../../../assets/img/success.png">
                    <p *ngIf="error_code == '200'">Successfully added to Watchlist.</p>
                    <p *ngIf="error_code == '209'">Successfully removed from Watchlist.</p>
                </div>
            </div>
        </div>
    </div>
</div>


<!--Cancel subscription -->
<div class="modal auth" id="add_watch_list_from_banner" *ngIf="userInfo">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">Are you sure want to add?</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeAddtoWatchListModal>
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-primary" (click)="addWatchList(loginContent)">Yes</button>
                <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                <a data-toggle="modal" data-target="#pack_removed_success"></a>
            </div>
        </div>
    </div>
</div>
<!-- Cancel subscription -->


<ng-template #content let-modal class="_parent_jw_m">
    <app-jw-video-player [streamInfo]="streamInfo" class="_p_jw_player"></app-jw-video-player>
</ng-template>


<ng-template #loginContent let-modal>
    <app-login></app-login>
</ng-template>
