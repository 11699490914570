import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legalpolicy',
  templateUrl: './legalpolicy.component.html',
  styleUrls: ['./legalpolicy.component.css']
})
export class LegalpolicyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
