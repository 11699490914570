<div class="maincontentarea text-white">
    <div class="container">
        <h1 class="text-center mb-4 mt-4">nexGTv Content Guidelines</h1>
        <div class="table-responsive">
            <table class="table table-bordered text-white">
                <thead>
                    <tr>
                        <th>S&nbsp;No.</th>
                        <th>Standard and Practice</th>
                        <th class="text-center">Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Sex, Obscenity and Nudity</td>
                        <td>
                            <ul>
                                <li>The Content should not include any sexual and, or obscene scenes nor expose any nudity of any person.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Violence and Crime</td>
                        <td>
                            <ul>
                                <li>The Content should not depict any form of violent, indecent, disturbing visuals, that lead to incite people to crime, cause disorder or violence or breach of law.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Harm and Offence</td>
                        <td>
                            <ul>
                                <li>The Content should not depict or promote any harm and, or, offence to any living creature including inter alia persons and animals.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Intellectual Property infringement</td>
                        <td>
                            <ul>
                                <li>The Content should not be infringing upon the intellectual property of any third party nor contain any unlicensed footage.</li>
                                <li>Content must not depict brands, logos, trademarks of third parties without their consent.</li>
                                <li>Content must not use any famous dialogues from movies/ web-series/ plays etc.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Smoking/ Drinking/ Substance Abuse</td>
                        <td>
                            No part of the Content must depict, encourage, or condone the use of tobacco products, alcohol, and narcotic substances. However:

                            <ul>
                                <li>If there is any part of the Content that displays the use of tobacco products, the Content must carry anti-tobacco health spots of minimum 30 seconds to be screened at the beginning and middle of a film/ programme;</li>
                                <li>If there is any part of the Content that displays the use of alcohol or narcotics, it is advisable to include a disclaimer that states “Drinking is injurious to health.”, or “Consumption of narcotics is injurious to health: and</li>
                                <li>Content must not show a minor drinking/ smoking/ drugs.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Sovereignty and Security of State</td>
                        <td>
                            <ul>
                                <li>Content must not affect the sovereignty and integrity of India in any manner; </li>
                                <li>Content must not threaten, endanger or jeopardize the security of the State; </li>
                                <li>Content which is detrimental to India’s friendly relations with foreign countries; </li>
                                <li>ontent which is likely to incite violence or disturb maintenance of public order. </li>
                                <li>The above apply to India and all other Territories</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Fake News</td>
                        <td>
                            <ul>
                                <li>Content must not contain cheating by personation;</li>
                                <li>Content must not contain false alarms, warnings to disaster or its severity and magnitude, leading to a panic;</li>
                                <li>Content which contains rumours or reports which may cause fear and alarm to the public;</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Horror and Occult</td>
                        <td>
                            <ul>
                                <li>Any Content in the “Horror and Occult” genre for the public at large to feel that the Licensor, endorses or encourages any activities related to occult, exorcism, paranormal, divination or human or animal sacrifice or other such practices must be avoided; </li>
                                <li>Prolonged, frequent depiction of horror must be avoided; </li>
                                <li>Content that will instil fear or glamorize topics such as ghosts and witches etc. must be avoided; and</li>
                                <li>Any Content that will encourage any superstition or blind belief should be avoided. </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Religion and Community</td>
                        <td>
                            The Content must not contain the following:
                            <ul>
                                <li>Any words (written or verbal) or signs or visual representations, which may be considered as insult/abuse/derogatory/feeling of hatred to any religion or religious beliefs, minorities, or castes; or</li>
                                <li>Any language, actions, abuse against  member of any caste, which may insult, humiliate, or intimidate such member; or</li>
                                <li>Any action against the  members of a particular disadvantaged caste unless the concept or storyline is such to highlight any social evils prevailing in the society against the disadvantaged caste; or</li>
                                <li>Destroying, damaging, or defiling any place of worship/object generally known to be sacred or in high esteem by religious groups or class of persons or SC/ST member; or</li>
                                <li>Any language in any Content, which may be construed as promoting enmity between different groups on ground of religion, race, place of birth, residence, language, etc., and doing acts prejudicial to maintenance of harmony; or</li>
                                <li>Act of sexual nature intentionally done to a person belonging to any caste without their consent unless the concept or storyline is such to highlight any social evils prevailing in the society; or</li>
                                <li>Using of words, acts, gestures of sexual nature towards a person belonging to members of any caste.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Usage of Animals in the Content</td>
                        <td>
                            <ul>
                                <li>If there is any part of the Content that displays animals, then insert a small disclaimer (to be shared by Legal Team) which will state that “no animal was hurt during the shoot of the Content”. </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>11</td>
                        <td>Gambling</td>
                        <td>
                            <ul>
                                <li>The use of gambling devices or scenes relevant to the development of the plot or as an appropriate background is acceptable. It should be presented with discretion, moderation and in a manner, which does not encourage or offer instructions in gambling.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>12</td>
                        <td>Defamatory Statements</td>
                        <td>
                            The Content must not contain the following:
                            <ul>
                                <li>Words that are directly used to demean or address inadequacies in mentally and physically challenged individuals should be avoided e.g. (langra, lula, aandha, chhakka, chinky, gurkha/nepali, chamar) etc.</li>
                                <li>Harsh/derogatory statements or remark on any person who is well known in society such as freedom fighters, celebrities, social workers, politicians, business tycoons etc. (living or dead), event or place in any Content; </li>
                                <li>Mockery of any religious or political figure in any Content; </li>
                                <li>Any comments in relation to established personalities which are not verified and which are considered in normal parlance as tasteless; and</li>
                                <li>Defamation or contempt of court like High court etc. or adversely affect the judicial process of the country in a derogatory manner. </li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>13</td>
                        <td>Use of National Flag and State Emblem</td>
                        <td>
                            <ul>
                                <li>National flag, emblem or official seal or emblem of the Government of India or of any State shall not be used in derogatory manner or in any manner which shall be interpreted as derogatory.</li>
                            </ul>
                        </td>
                    </tr>
                    <tr>
                        <td>14</td>
                        <td>Data Protection and Privacy</td>
                        <td>
                            <ul>
                                <li>To comply with all laws in all such jurisdictions as enjoined by their operations relating to collection, processing and transfer of personal and personally identifiable information;</li>
                                <li>To implement information security systems and report any incidents of violation or disclosure of confidential or personal data;</li>
                                <li>To handle and process data only for the purpose(s) as mentioned in the contractual agreement; and</li>
                                <li>To strictly avoid usage of unapproved messaging platforms for business communication involving any commercial transactions.</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>