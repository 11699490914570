import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core';
import { CatlougeTypeConstants } from 'src/app/core/constants';
import { AssetsService } from 'src/app/core/services/assets.service';
import { customDataForAssets, customDataForBanner, customDataForDummyData, customOptionsForBigSlider, customOptionsForFourMiniSlider, customOptionsForSevenMiniSlider, customOptionsForSixMiniSlider, customOptionsForThreeMiniSlider } from 'src/app/shared/owl-slider-config';
import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-dynamic-tab',
  templateUrl: './dynamic-tab.component.html',
  styleUrls: ['./dynamic-tab.component.css']
})
export class DynamicTabComponent implements OnInit {
  progressInc: number = 0;
  progrssBarValue: number = 0;
  userInfo: any;
  Location: any;
  owlBannerData: any = {};
  owlSliderData: any = customDataForDummyData;
  customOptionsForBigSlider = customOptionsForBigSlider
  customOptionsForFourMiniSlider = customOptionsForFourMiniSlider;
  customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
  customOptionsForSixMiniSlider = customOptionsForSixMiniSlider;
  customOptionsForThreeMiniSlider = customOptionsForThreeMiniSlider;
  private currentMenuSubject = new BehaviorSubject(false);
  public activeMenu = this.currentMenuSubject.asObservable();
  getCurrentSlug: string;
  
  homePageAssets: any;
  page: number = 1;
  tabDataParams: any;
  limit: number = 15;
  playListId: string;
  playListService: any;
  playListAvailable: boolean;
  playlist: any;
  routeName: any;
  router: any;
  tempAsset_id: any;
  continueWatchingDataParams: any;
  loadMore: boolean;
  showContinueWatching: string;
  showAddBanner:boolean = false;


  constructor(public activatedRoute: ActivatedRoute, public userService: UserService, public assetsService: AssetsService) {
    console.log('this.activeMenu', this.activeMenu);

    this.showContinueWatching = localStorage.getItem('continuewatching');
    this.getCurrentSlug = userService.getCurrentSlugName;
    this.liniarProgressBar();

    // this.customOptionsForSevenMiniSlider = customOptionsForSevenMiniSlider;
    // this.customOptionsForSevenMiniSlider['slides'] = 7;
  }

  ngOnInit(): void {
    // this.userService.updateSelectedMenu('home');
    this.activatedRoute.params.subscribe(param => {
      // console.log('9089089'+param);
    });
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));

    let continueWatchingtabDataParams = {
      "catlogue": CatlougeTypeConstants.catlogue,
      "slug": this.getCurrentSlug,
      "call": "continuewatching",
      "page": 1,
      "limit": this.limit
    };

    this.tabDataParams = {
      "catlogue": CatlougeTypeConstants.catlogue,
      "platform": "web",
      "slug": this.getCurrentSlug,
      "call": "index",
      "page": 1,
      "limit": this.limit
    };
    this.continueWatchingDataParams = this.userService.convertoJSONToUrlEncoded(continueWatchingtabDataParams);
    let dynamicTabsDataParams = this.userService.convertoJSONToUrlEncoded(this.tabDataParams);
    setTimeout(()=>{
      this.continueWatchingData(this.continueWatchingDataParams);
      this.getHomePageAssets(dynamicTabsDataParams);
    }, 50)

    // setTimeout(() => {
    //   this.getHomePageAssets(dynamicTabsDataParams);
    // }, 50)
    
    // this.assetsService.getContinueWatchingData(continueWatchingDataParams).subscribe(data => {
    //   this.owlBannerData = data;
    //   setTimeout(() => {
    //     this.assetsService.getHomePageTabData(dynamicTabsDataParams).subscribe(data => {
    //       this.owlSliderData = data;
    //     });
    //   }, 5000,
    //     document.getElementById("nexg_ngb-progressbar").style.display = 'none',
    //     document.getElementById('maincontentarea').classList.remove("_beforeLoad")
    //   );
    // });

    // setTimeout(() => {
    //   document.getElementById("nexg_ngb-progressbar").style.display = 'none';
    //   document.getElementById('maincontentarea').classList.remove("_beforeLoad");
    // }, 500);

  }

  continueWatchingData(continueWatchingDataParams) {
    //this.owlBannerData.banner_result.banner = "";
    this.assetsService.getContinueWatchingData(continueWatchingDataParams).subscribe(data => {
      this.owlBannerData = data;
      this.homePageAssets = data;
      console.log("result-------", this.owlBannerData); 
      setTimeout(() => {
        document.getElementById("nexg_ngb-progressbar").style.display = 'none';
        document.getElementById('maincontentarea').classList.remove("_beforeLoad");
      }, 2000);
    });
  }

  getHomePageAssets(dynamicTabsDataParams) {
    this.assetsService.getHomePageTabData(dynamicTabsDataParams).subscribe(data => {
      this.owlSliderData = data;
    });
  }





  onScroll() {
    this.loadMore = true;
    let maxPage = Math.ceil(this.owlSliderData.total_count / this.limit);
    this.page++;
    this.tabDataParams['page'] = this.page;
    this.tabDataParams['limit'] = this.limit;
    if (maxPage >= this.page) {
      let dynamicTabsDataParams = this.userService.convertoJSONToUrlEncoded(this.tabDataParams);
      setTimeout(() => {
        this.assetsService.getHomePageTabData(dynamicTabsDataParams).subscribe(data => {
          // this.scrollNow =  true;
          
          let rs = data.result;
          let preData = this.owlSliderData.result;
          this.owlSliderData.result = [...preData, ...rs];
          this.loadMore = true;
          // this.owlSliderData.result = { ...this.owlSliderData.result, ...data.result }
  
          // Object.assign(this.owlSliderData, ...data);
  
          // this.owlSliderData = {
          //   ...data
          // };
  
          // let dataIS = this.owlSliderData;
          // this.owlSliderData = {dataIS, ...data};
        });
      }, 1000);
   
    }else{
      this.loadMore = false;
    }

  }


  deleteRecentWatchItem(event) {
    console.log(event);

    let categoryIndex = this.homePageAssets.result.findIndex((ele) => {
      return ele.category_code == 'HCTG008'
    })


    if (categoryIndex != -1) {
      // [categoryIndex]
      let assetsDataIndex = this.homePageAssets.result[categoryIndex].assets.findIndex((ele) => {
        return ele._id == event._id
      })

      // let recentWatchedDelete = {
      //   type: "",
      //   action: 'delete',
      //   catlogue: CatlougeTypeConstants.catlogue,
      //   id: ""
      // }
      let recentWatchedDelete = {
        catlogue: CatlougeTypeConstants.catlogue,
        slug: 'home',
        call: 'continuewatching'
      }

      if (event.type == 'episode' || event.type == 'tvshow') {
        recentWatchedDelete['type'] = event.type;
        recentWatchedDelete['recentlywatch_id'] = event.show_id;
        recentWatchedDelete['removerecord'] = 'recentlywatched'
      } else {
        recentWatchedDelete['type'] = event.type;
        recentWatchedDelete['recentlywatch_id'] = event.asset_mongo_id ? event.asset_mongo_id : event._id;
        recentWatchedDelete['removerecord'] = 'recentlywatched'
      }

      let urlEncodedBodyData = this.userService.convertoJSONToUrlEncoded(recentWatchedDelete);
      this.assetsService.deleteContinueWatching(urlEncodedBodyData).subscribe(data => {
        this.homePageAssets.result[categoryIndex].assets.splice(assetsDataIndex, 1);
      });
    }
  }



  liniarProgressBar() {
    this.progressInc = 0;
    let progrssInterval = setInterval(() => {
      this.progrssBarValue = this.progressInc * 10;

      if (this.progressInc >= 10) {
        clearInterval(progrssInterval);
      }
      this.progressInc++;
      console.log(this.progrssBarValue);
    }, 500)

  }


  getWatchList() {

    this.playListId = localStorage.getItem('playListId');
    let temp = {
      playlistid: this.playListId ? this.playListId : '',
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "getPlayListAsset",
    }

    let urlEncodedPlayListAssetsParams = this.userService.convertoJSONToUrlEncoded(temp);
    this.playListService.getPlaylist(urlEncodedPlayListAssetsParams).subscribe(data => {
      if (data && data.result && Array.isArray(data.result)) {
        if (Array.isArray(data.result)) {
          this.playListAvailable = true;
          this.playlist = data.result;
          // console.log(this.playlist);
          this.check_into_watchlist()
          return this.playlist;
        }
      }
    });

  }



  check_into_watchlist() {
    this.routeName = this.router.url;
    console.log(this.routeName);
    this.homePageAssets?.result.forEach((categoryData, categoryIndex) => {

      if (categoryData.assets.length > 0) {
        this.homePageAssets?.result[categoryIndex].assets.forEach((tempAsset, tempIndex) => {

          if (this.routeName == '/') {
            this.tempAsset_id = tempAsset.asset_mongo_id;
          } else if (this.routeName == '/tvshows') {
            this.tempAsset_id = tempAsset.asset_mongo_id;
          } else if (this.routeName == '/movies') {
            this.tempAsset_id = tempAsset._id;
          } else if (this.routeName == '/livetv') {
            this.tempAsset_id = tempAsset._id;
          }

          if (this.playlist.findIndex(tempObj => tempObj._id == this.tempAsset_id) != -1) {
            this.homePageAssets.result[categoryIndex].assets[tempIndex].is_watchlist_added = true;
          } else {
            this.homePageAssets.result[categoryIndex].assets[tempIndex].is_watchlist_added = false;
          }
        });
      }

      if (categoryIndex >= this.homePageAssets?.result.length - 1) {
        this.owlSliderData = this.homePageAssets;
        document.getElementById("nexg_ngb-progressbar").style.display = 'none';
        document.getElementById('maincontentarea').classList.remove("_beforeLoad");
        return true;
      }
    });
    // console.log(this.homePageAssets);
  }
}
