<div class="maincontentarea">
    <div class="container">
        <div class="faq">
            <h1 class="text-center">FAQs</h1>
            <div class="container-txt">
                <h3>Q1) What all can I watch on nexGTv  ?</h3>
                <p>nexGTv is a one-stop entertainment destination offering its users a high-quality viewing experience across 100+ Live TV channels, Movies, TV Shows and Video on Demand.</p>
                
                <h3>Q2) Is there any content on nexGTv which is available free and that can be watched without subscribing?</h3>
                <p>nexGTv offers selected Live TV channels and Video on Demand content absolutely free to watch. However data charges will be applicable as per the data plan opted by the subscriber.</p>
                
                <h3>Q3) Where do I get to choose the different types of subscription packages available within the nexGTv application?</h3>
                <p>On clicking on any of the paid channel/movie/video, you will be directed to a page which will display all the subscription packages. You can subscribe to any of the available packages as per your choice.</p>
                
                <h3>Q4) I am not able to stream any of channel or video, what should I do?</h3>
                <p>The problem might be because there is not enough network coverage in your region. It is suggested to set your streaming option to low quality if network signal is weak. If problem still persists, please mail us at  <a href="mailto:customercare@digivive.com">customercare@digivive.com</a></p>
                
                <h3>Q5) I am trying to subscribe to a package. How long will it take to activate the subscription?</h3>
                <p>The subscription will be activated instantaneously once the payment is successful. Post which you can start enjoying your nexGTv subscription.</p>
                
                
                <h3>Q6) Does nexGTv provide search functionality?</h3>
                <p>Yes, nexGTv application provides an advanced search functionality for its users to easily find content they are looking for. You need to type the search keyword in the search box available. On pressing OK you will be shown the relevant Live channels and Videos search results from where you can directly play the content.</p>
                
                <h3>Q7) Can I watch nexGTv on PC /Laptop as well?</h3>
                <p>nexGTv now offers multi-screen viewing of your favourite content with the same subscription. You can enjoy nexGTv service across all your personal screens - PC/Laptop/Mobile/Smart TV devices by subscribing only once. For more details please go through our website <a routerLink="/">www.nexgtv.com</a></p>
                
                <h3>Q8) Can I watch nexGTv globally?</h3>
                <p>Yes, nexGTv is available globally and can be watched outside of India as well. However, the content catalouge may vary as per respective territory.</p>
                
                <h3>Q9) How to contact nexGTv in case of any support?</h3>
                <p>For any query, please mail us at <a href="mailto:customercare@digivive.com">customercare@digivive.com</a></p>
                
                <!-- <h3>Q10) If I watch a content on one device, will my watch history be retained on another device?</h3>
                <p>User’s browsing and watch history is retained on all devices.</p>
                
                <h3>Q11) I am trying to subscribe to a pack. How long will it take to activate the subscription?</h3>
                <p>The subscription will be activated instantaneously once the payment is successful. Post which you can start enjoying your Zonet Empai subscription.</p>
                
                <h3>Q12) I don’t have any premium pack but when I’m trying to purchase a premium pack, I’m getting a popup that I’m already subscribed to the pack or that my device already has an active subscription.</h3>
                <p>You’re getting the popup due to one of the following reasons</p>
                <ol>
                    <li>You’re trying to purchase the pack on an iOS device which is logged in with an Apple ID already having the same Zonet Empai premium subscription. To purchase a Zonet Empai premium pack on an iOS device, make sure that the device is signed in with an Apple ID which is not subscribed to the same pack.</li>
                    <li>You’re trying to purchase the pack on an Android TV device which is logged in with a Google account already having the same Zonet Empai premium subscription. To purchase a Zonet Empai premium pack on an Android TV device, make sure that the device is signed in with a Google which is not subscribed to the same pack.</li>
                    <li>You’re trying to purchase the pack on an Android mobile app through Google Play Store which is logged in with a Google account already having the same Zonet Empai premium subscription. To purchase a Zonet Empai premium pack on an Android mobile app using Google Play Store, make sure that Google Play Store is signed in with a Google account which is not subscribed to the same pack.</li>
                </ol>
                
                
                <h3>Q13) How do I cancel a Zonet Empai subscription?</h3>
                <p>If you purchased a Zonet Empai subscription pack through wallet (Paytm, RazorPay), you can cancel the subscription using the below methods. </p>
                <p>Zonet Empai Android app</p>
                <ul>
                    <li>Open the Zonet Empai Android app</li>
                    <li>Login with your username and password</li>
                    <li>Click More</li>
                    <li>Open My Subscriptions</li>
                    <li>Click Unsubscribe</li>
                    <li>Click Yes to confirm</li>
                </ul>
                <p>Zonet Empai Website</p>
                <ul>
                    <li>Login with your Zonet Empai username and password on Zonet Empai website</li>
                    <li>Open My Accounts page</li>
                    <li>Scroll down to Plan Details</li>
                    <li>Click Unsubscribe</li>
                    <li>Click Yes to confirm</li>
                </ul>
                <p>If you purchased a Zonet Empai subscription pack through Google Play Store, you can cancel the subscription using the below steps</p>
                <ul>
                    <li>Sign in to Google Play Store using the account with which you purchased the pack</li>
                    <li>In Menu, open Payments & subscriptions</li>
                    <li>Open Subscriptions</li>
                    <li>Select Zonet Empai subscription</li>
                    <li>Click on Cancel subscription</li>
                    <li>Click Confirm</li>
                </ul>
                <p>If you purchased a Zonet Empai subscription through Apple iTunes, you can cancel the subscription using the below steps</p>
                <ul>
                    <li>Open Settings of your iOS device signed in with the same Apple ID with which you purchased the pack</li>
                    <li>Open Subscriptions</li>
                    <li>From the list of active subscriptions, open Zonet Empai subscription details</li>
                    <li>Click on Cancel </li>
                </ul>
                
                <h3>Q14) How to contact Zonet Empai in case of any support?</h3>
                <p>For any query, mail us at <a href="mailto:support@zonet.tv">support@zonet.tv</a></p> -->
            </div>            
        </div>
    </div>
</div>