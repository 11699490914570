<!-- Login -->

<div *ngIf="loginPageStatus">
  <app-login></app-login>
</div>


<!-- Registration -->

<div class="auth" id="register">

  <div class="modal-dialog" *ngIf="signUpPageStatus">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="!verifySuccessMsg && !tellUsScreen">{{!otpScreen?'User Registration':'Verify
          OTP'}} </h4>
        <!-- <h4 class="modal-title" >{{!otpScreen?'User Registration':'Verify OTP'}} </h4> -->
        <h4 class="modal-title" *ngIf="tellUsScreen">Tell Us About You </h4>

        <button type="button" class="close" (click)="closeModal()" #closebutton>&times;</button>
        <!-- <p class="_user_exist"> Hello{{ userExist }}</p> -->
      </div>



      <!-- Modal body -->
      <!-- signUpPageStatus -->
      <div class="modal-body" *ngIf="!otpScreen && regsterScreen">

        <form [formGroup]="registerForm" (ngSubmit)="register()">
          <!-- <div class="form-group input-group">
          <input type="text" class="form-control" id="confirm_password" formControlName="first_name" placeholder="First Name">
        </div> -->

          <div class="form-group" *ngIf="timezone !== 'Asia/Calcutta'">
            <input type="email" autocomplete="off" autocomplete="new-password" class="form-control"
              formControlName="emailaddress" id="RegisterUser" placeholder="Enter Email ID"
              [ngClass]="{ 'is-invalid': isSignUpSubmited && registerFormControls?.emailaddress?.errors }">

            <div *ngIf="isSignUpSubmited && registerFormControls?.emailaddress?.errors"
              class="invalid-feedback _require_filed">
              <span *ngIf="registerFormControls?.emailaddress.errors?.required">Please enter a email ID</span>
              <span *ngIf="registerFormControls?.emailaddress.errors?.pattern">Please enter a valid email ID.</span>
              <!-- <div *ngIf="registerFormControls.pass.errors.minlength">Password must be at least 6 characters</div> -->
            </div>
          </div>

          <div class="form-group" *ngIf="timezone === 'Asia/Calcutta'">
            <input type="number" pattern="\d*" maxlength="10" autocomplete="new-password" class="form-control"
              formControlName="mobile" id="mobile" (keypress)="commonService.mobileLimit($event)"
              placeholder="Mobile Number"
              [ngClass]="{ 'is-invalid': isSignUpSubmited && registerFormControls.mobile.errors }">
            <span class="m_country">+91</span>
            <div *ngIf="isSignUpSubmited && registerFormControls.mobile.errors" class="invalid-feedback _require_filed">
              <span *ngIf="registerFormControls.mobile.errors.required">Please enter a mobile number</span>
              <span
                *ngIf="!registerFormControls.mobile.errors.pattern && registerFormControls.mobile.errors.minlength">Please
                enter 10 digit mobile number</span>
              <span *ngIf="registerFormControls.mobile.errors.pattern">Please enter 10 digit mobile number</span>
            </div>
          </div>


          <div class="form-group input-group bot-error password-error">
            <input [type]="passHide ? 'password' : 'text'" class="form-control" id="password" formControlName="pass"
              placeholder="Password" (keypress)="commonService.avoidSpace($event)"
              [ngClass]="{ 'is-invalid': isSignUpSubmited && registerFormControls.pass.errors }" autocomplete="new-password">



            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-eye" (click)="passHide=passHide?false:true"></i></span>
            </div>
            <span class="right-msg" *ngIf="!isSignUpSubmited">Minimum 8 Characters</span>
            <div *ngIf="isSignUpSubmited && registerFormControls.pass.errors" class="invalid-feedback _require_filed">
              <span *ngIf="registerFormControls.pass.errors.required">Please enter a password</span>
              <span class="_long_otp" *ngIf="registerFormControls.pass.errors.minlength">Password must be at least 8
                characters</span>
            </div>
          </div>
          




          <div class="form-group input-group password-error">
            <input [type]="cPassHide ? 'password' : 'text'" formControlName="cpass" class="form-control"
              (keypress)="commonService.avoidSpace($event)" id="confirm_password"
              [ngClass]="{ 'is-invalid': isSignUpSubmited && registerFormControls.cpass.errors }"
              placeholder="Confirm Password" autocomplete="new-password">

            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fa fa-eye" (click)="cPassHide=cPassHide?false:true"></i></span>
            </div>

            <div *ngIf="isSignUpSubmited && registerFormControls.cpass.errors" class="invalid-feedback _require_filed">
              <span *ngIf="registerFormControls.cpass.errors.required">Confirm password does not match</span>
              <!-- <div *ngIf="registerFormControls.cpass.errors.minlength">Password must be at least 8 characters</div> -->
              <span *ngIf="registerFormControls.cpass.errors.mustMatch">Confirm password does not match</span>
            </div>
            <!-- <div *ngIf="registerForm.hasError(MustMatch)">Confirm Password show be match</div> -->
          </div>
          <!-- <div class="form-group input-group last-margin">
        <input type="date" class="form-control" formControlName="confirmPass" id="dob" placeholder="Date of Birth">
        <div class="input-group-prepend">
          <span class="input-group-text"><i class="fa fa-calendar" aria-hidden="true"></i></span>
        </div> 
      </div> -->
          <div class="checkbox signup-check">
            <a (click)="chnageRegisterVia($event)">Register via
              {{timezone == ''?'Mobile Number':'Email ID'}}
            </a>
          </div>
          <div class="terms">
            <p>By continuing, you agree to our <a href="javascript:void(0);" (click)="termsPageFn()">Terms & Privacy
                Policy</a></p>
          </div>
          <div class="invalid-feedback _oth_requiered"
            style="display: block !important; text-align: center !important;">
            <!-- <span *ngIf="userExistLimit != 'Your account is blocked for OTP service. Please go back and try again after 1 hr or Contact for support.'">{{userExist}}</span>
        <span *ngIf="userExistLimit == 'Your account is blocked for OTP service. Please go back and try again after 1 hr or Contact for support.'">Your account is blocked for OTP service. Please go back and try again after 1 hr or <a href='/contactus'>Contact</a> for support.</span> -->
            <span *ngIf="show"> {{fristString}}
              <a *ngIf="show" href="javascript:void(0);" (click)="goToHome()" aria-disabled="true" data-dismiss="modal">
                Contact
              </a>
              {{secondString}}</span>

            <span *ngIf="!show">{{userExistLimit}}</span>
            <span class="invalid-feedback _oth_requiered"
              style="color: red;text-align: center !important;">{{showErrorString}}</span>

          </div>

          <button type="submit" class="btn btn-default register">Proceed</button>
          <div class="loginlink">
            <p>Have an account? <a href="javascript:;" (click)="login()">Login</a></p>
          </div>

        </form>

        <!-- <button (click)="signin(8090184264,12345678)">Test</button> -->

      </div>


      <!-- Verify OTP -->
      <div class="modal-body" *ngIf="otpScreen">
        <div class="_otp_sent_p" *ngIf="!verifySuccessMsg">
          <!-- <p class="otp-center">One Time Password has been sent to</p> -->
          <p class="otp-email" *ngIf="timezone =='Asia/Calcutta'">Please enter the OTP we have sent via SMS to your
            {{mobileNumber}}</p>
          <p class="otp-email" *ngIf="timezone !='Asia/Calcutta'">Please enter the OTP we have sent to your {{emailId}}
          </p>

          <!-- <p class="otp-email" *ngIf="timezone =='Asia/Calcutta'">{{verifyForm.value.mobile}}</p>
          <p class="otp-email" *ngIf="timezone !='Asia/Calcutta'">{{verifyForm.value.emailaddress}}</p> -->
        </div>

        <div *ngIf="verifySuccessMsg">
          <div class="success-add">
            <img src="../../../assets/img/success.png">
            <h2 style="color:#fff;margin-top: 80px !important;font-size: 20px;font-weight: 100;line-height: 35px;"
              class="_verify_t">
              You have successfully registered with
              <span style="font-weight: 500;" *ngIf="timezone =='Asia/Calcutta'"> {{verifyForm.value.mobile}}</span>
              <span style="font-weight: 500;" *ngIf="timezone !='Asia/Calcutta'">
                {{verifyForm.value.emailaddress}}</span>
            </h2>
            <!-- <h2> {{verifySuccessMsg}}</h2> -->
          </div>
        </div>

        <div *ngIf="!verifySuccessMsg">
          <form [formGroup]="verifyForm" (ngSubmit)="verifyOtp()" class="resettwo">
            <div class="form-group">
              <input type="text" pattern="\d*" maxlength="6" autocomplete="off" (keyup)="clearResponseError()"
                class="form-control" id="otp" formControlName="otp" id="otp" placeholder="Enter OTP"
                [ngClass]="{ 'is-invalid': isOtpSubmitted && otpFormControls.otp.errors }">

              <!-- <div *ngIf="isOtpSubmitted && otpFormControls.otp.errors" class="invalid-feedback _require_filed _mob_required">
              
                <span class="_long_otp_login_1" *ngIf="otpFormControls.otp.errors.required">Please enter the OTP we have sent to your Mobile number/Email ID</span>
                <span class="_long_otp_login_1" *ngIf="!otpFormControls.otp.errors.required && otpFormControls.otp.errors.pattern">Please enter the OTP we have sent to your Mobile number/Email ID</span>
                  <span class="_long_otp_login_1" *ngIf="!otpFormControls.otp.errors.required && !otpFormControls.otp.errors.pattern &&  otpFormControls.otp.errors.minlength">Please enter the OTP we have sent to your Mobile number/Email ID</span>
            </div> -->
            </div>
            <!-- <div class="input-group-prepend">
            <span class="input-group-text"><i class="fa fa-eye"  (click)="otpHide=otpHide?false:true" ></i></span>
          </div> -->

            <p class="forgot" *ngIf="error_code != '429'">
              <a (click)="requestForOtp()" class="resend" *ngIf="otpInt <= 0">Resend OTP</a>
              <a class="resend" *ngIf="otpInt > 0" style="color: #ccc;opacity: 0.5;">Resend OTP</a>
            </p>

            <div class="_custom_require_f">

              <div *ngIf="isOtpSubmitted && otpFormControls.otp.errors"
                class="invalid-feedback _require_filed _mob_required" style="display: block !important;">
                <span class="_long_otp_login_1" *ngIf="otpFormControls.otp.errors.required">Please enter the OTP</span>
                <span class="_long_otp_login_1"
                  *ngIf="!otpFormControls.otp.errors.required && otpFormControls.otp.errors.pattern">Please enter the
                  OTP</span>
                <span class="_long_otp_login_1"
                  *ngIf="!otpFormControls.otp.errors.required && !otpFormControls.otp.errors.pattern &&  otpFormControls.otp.errors.minlength">Please
                  enter the OTP</span>
              </div>

              <div class="invalid-feedback _oth_requiered"
                style="display: block !important; text-align:center !important;">
                <span>{{showErrorString}}</span>
                <span *ngIf="show"> {{fristString}}
                  <a *ngIf="show" href="javascript:void(0);" (click)="goToHome()" aria-disabled="true"
                    data-dismiss="modal">
                    Contact
                  </a>
                  {{secondString}}</span>

                <span *ngIf="!show">{{userExistLimit}}</span>

                <span class="invalid-feedback _oth_requiered"
                  style="color: red;text-align: center !important;">{{verifyOTPErrorString}}</span>
                <!-- <span *ngIf="userExistLimit != 'Your account is blocked for OTP service. Please go back and try again after 1 hr or Contact for support.'">{{userExist}}</span>
              <span *ngIf="userExistLimit == 'Your account is blocked for OTP service. Please go back and try again after 1 hr or Contact for support.'">Your account is blocked for OTP service. Please go back and try again after 1 hr or <a href='javascript:void(0)' routerLink='/contactus'>Contact</a> for support.</span> -->
              </div>

              <!-- <div class="invalid-feedback _oth_requiered _time_otp long_otp_position" style="display: block !important; text-align:center !important;"> 
              <span *ngIf="otpInt > 0 && timezone =='Asia/Calcutta'  && !userExist && maxOtpAttempt > 0">
                  OTP successfully sent to {{verifyForm.value.mobile}}
              </span>
              <span class="long_otp_email fulwidth" *ngIf="otpInt > 0 && timezone != 'Asia/Calcutta' && !userExist && maxOtpAttempt > 0">
                OTP successfully sent to {{verifyForm.value.emailaddress}}
            </span>
            </div> -->

              <!-- <div class="invalid-feedback _oth_requiered _time_otp long_otp_position" 
              style="display: block !important; text-align:center !important;" *ngIf="error_code == '428'"> 
              <span class="long_otp_email"  *ngIf="verifyForm.value.mobile">
              OTP successfully resent to {{verifyForm.value.mobile}} if OTP not received, 
              please go back and try again or
              contact customercare@digivive.com for support 
            </span>
            <span class="long_otp_email" *ngIf="verifyForm.value.emailaddress">
              OTP successfully resent to {{verifyForm.value.emailaddress}} if OTP not received, 
              please go back and try again or
              contact customercare@digivive.com for support 
            </span>
        
            </div>  -->
            </div>

            <button type="submit" class="btn btn-default register _otp_reg">Confirm</button>
          </form>
        </div>

      </div>



      <!-- TellUs Modal -->
      <div class="modal-body" *ngIf="tellUsScreen">

        <form [formGroup]="tellUsForm" (ngSubmit)="postTellUs()" class="resettwo tellus_view">

          <div *ngIf="tellUsSuccessMsg">
            <div class="success-add">
              <img src="../../../assets/img/success.png">
              <h2 style="color:#fff;margin-top: 80px !important;font-size: 20px;font-weight: 100;line-height: 35px;"
                class="_verify_t">
                Your TellUs Form Submitted successfully
              </h2>
            </div>
          </div>

          <!-- {{tellUsAllFieldsArray|json}} -->
          <div *ngFor="let tell of tellUsAllFieldsArray">
            <div *ngIf="tell.type=='input'" class="form-group">
              <div class="row">
                <div class="col-md-12">

                  <div class="scroll_height">
                    <input type="text" class="form-control" [formControlName]="tell.slug" placeholder="{{tell.title}}">
                  </div>
                  <div *ngIf="isTellUsSubmitted && tellFormControls[tell.slug].errors" class="_require_filed">
                    <span
                      *ngIf="tellFormControls[tell.slug].hasError('required') && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched || tellFormControls[tell.slug].errors.required)">
                      {{tell.error_message}}
                    </span>
                  </div>

                </div>
              </div>
            </div>
            <div *ngIf="tell.type=='input_calender'" class="form-group">
              <div class="row">
                <div class="col-md-4">
                  <label class="tellus_view_label">{{tell.title}}</label>
                </div>

                <div class="col-md-8" style="padding: 0;">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="scroll_height">
                        <select class="form-control" name="day" id="day" formControlName="day">
                          <option>Day</option>
                          <option *ngFor="let item of [].constructor(31); let i = index" value="{{i+1}}">{{i+1}}
                          </option>
                        </select>
                      </div>
                      <div class="_require_filed">
                        <span
                          *ngIf="isTellUsSubmitted  && tellFormControls.day.errors && (tellFormControls.day.dirty || tellFormControls.day.touched || tellFormControls.day.errors.pattern)">Select
                          Day
                        </span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <select class="form-control" name="month" id="month" formControlName="month">
                        <option>Month</option>
                        <option *ngFor="let item of [].constructor(12); let i = index" value="{{i+1}}">{{i+1}}</option>
                      </select>
                      <div class="_require_filed">
                        <span
                          *ngIf=" isTellUsSubmitted && tellFormControls.month.errors && (tellFormControls.month.dirty || tellFormControls.month.touched || tellFormControls.month.errors.pattern)">Select
                          Month
                        </span>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <select class="form-control" name="year" id="year" formControlName="year">
                        <option>Year</option>
                        <option *ngFor="let item of [].constructor((d-1950)+1); let i = index" value="{{i+1950}}">
                          {{i+1950}}
                        </option>
                      </select>
                      <div class="_require_filed">
                        <span
                          *ngIf="isTellUsSubmitted  && tellFormControls.year.errors &&  (tellFormControls.year.dirty || tellFormControls.year.touched || tellFormControls.year.errors.pattern)">Select
                          Month
                        </span>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <!-- <div
                        *ngIf='isTellUsSubmitted && (tellFormControls.day.errors && tellFormControls.month.errors && tellFormControls.year.errors )'
                        class="_require_filed">
                        <span
                          *ngIf='tellFormControls.day.hasError("required") || tellFormControls.month.hasError("required") || tellFormControls.year.hasError("required") && (tellFormControls.day.errors.required || tellFormControls.month.errors.required || tellFormControls.year.errors.required)'>
                          {{tell.error_message}}
                        </span> -->
                      <!-- <span
                          *ngIf='tellFormControls.day.hasError("required") || tellFormControls.month.hasError("required") || tellFormControls.year.hasError("required") && (tellFormControls.day.errors.required || tellFormControls.month.errors.required || tellFormControls.year.errors.required)'>
                          {{tell.error_message}}
                        </span> -->
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" *ngIf="tell.type=='radio'">
              <div class="row">
                <div class="col-md-4">
                  <label class="tellus_view_label">Gender </label>
                </div>
                <div class="col-md-8" style="padding: 0;">
                  <div class="row">
                    <!--*ngFor="let rad of tell.attributes"-->
                    <div *ngFor="let rad of tell.attributes" class="col-md-4" style="padding-right: 0;">
                      <label class="gander_radio">
                        <input type="radio" name="{{tell.slug}}" formControlName="{{tell.slug}}"
                          value="{{rad}}">{{rad}}</label>
                    </div>
                    <div class="col-md-12">
                      <div *ngIf='isTellUsSubmitted && tellFormControls[tell.slug].errors ' class="_require_filed">
                        <span
                          *ngIf='tellFormControls[tell.slug].hasError("required") && (tellFormControls[tell.slug].dirty || tellFormControls[tell.slug].touched || tellFormControls[tell.slug].errors.required)'>
                          {{tell.error_message}}
                        </span>

                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>

          <button type="submit" class="btn btn-default register _otp_reg">Done</button>
        </form>
      </div>

    </div>
  </div>
  <!-- {{otpScreen}} -->
  <div *ngIf="otpScreen">
    <!-- <app-verifyotp></app-verifyotp> -->
    <button type="button" [hidden]="false" class="dropdown-item" (click)="openTellUs(content)"
      #openTellUsModel>Login</button>
  </div>

  <!--Registration-->

  <ng-template #content let-modal>
    <app-tell-us></app-tell-us>
  </ng-template>