import { Component, OnInit, Inject, Input, Output, EventEmitter, ViewChild, HostListener, Renderer2, AfterViewInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as $ from 'jquery'
import { AssetsService } from "../../core/services/assets.service";
import { Errors, UserService } from '../../core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UserTypeConstants, CatlougeTypeConstants } from "src/app/core/constants";
import { PlayListService } from "src/app/views/playlist/playlist/playlist.service";
import { CommonService } from "src/app/core/services/common.service";
import { DOCUMENT } from '@angular/common';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { Subscription } from 'rxjs';
declare var videojs;
declare var $;
@Component({
  selector: 'app-owl-banner-slider',
  templateUrl: './owl-banner-slider.component.html',
  styleUrls: ['./owl-banner-slider.component.css'],
  providers: [UserService, PlayListService]
})
export class OwlBannerSliderComponent implements OnInit {

  @Input() owlOption: OwlOptions;
  @Input() data: any = {};
  @Input() page_view;
  @Output() deleteRecentWatch: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closeAddtoWatchListModal') closeAddtoWatchListModal;
  @ViewChild('addToWatchListSuccessFromBanner') addToWatchListSuccessFromBanner;
  public bannerResult: any = null;
  streamInfo: any;
  public userInfo: any;
  setWatchLitemItem: any;
  playList: any;
  playListId: string;
  watchListAddedItem: any;
  error_string: any;
  addedAssetIndex: any;
  playListAvailable: boolean;
  playlist: any;
  homePageAssets: any;
  deleteAsset_id: any;
  error_code: any;
  isCloudFrontType: boolean = false;
  notFoundImg: any;

  is_preview: any = [];
  playermodal: any = [];
  playUrlResObj: any;
  is_muted: boolean = true;
  timeDuration: any;
  showfewSecondImage: any = [];
  subscription: Subscription;
  activeSlideIndex: number = 2;
  currentPlayedIndex: number;
  addBefourVideo1Second: any = [];


  // updateAutoplayTimeout(newTimeout: number) {
  //   this.owlOption.autoplayTimeout = newTimeout;
  // }


  constructor(
    public AssetsService: AssetsService,
    public UserService: UserService,
    private modalService: NgbModal,
    public playListService: PlayListService,
    public commonService: CommonService,
    @Inject(DOCUMENT) private document: Document,
    private googleEvent: EventTrackingService,
    private renderer: Renderer2
  ) {

    // this.subscription = commonService.owlSliderOptionActivity$.subscribe(
    //   activity_d => {
    //     // console.log('activity data', activity_d);
    //     this.timeDuration = 30000;
    //     this.owlOption.responsive['1300'].autoplayTimeout = activity_d;
    //   })

  }


  ngOnInit() {
    $(".dark").addClass("homeAndDetailPage");
    console.log('ngOnInit')
    this.notFoundImg = "landscape";
    if (this.commonService.filterType == 1) {
      this.isCloudFrontType = true;
    } else {
      this.isCloudFrontType = false;
    }
    if (localStorage.getItem('userInfo')) {
      this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
      this.playListId = localStorage.getItem('playListId');
    }


    this.bannerResult = JSON.parse(JSON.stringify(this.data));

    let isIos = /iPhone/i.test(window.navigator.userAgent)

    if (this.userInfo) {
      this.getWatchList();
    }

    if (isIos) {
      this.owlOption.autoplay = false;
      delete this.owlOption.responsive['0'].autoplayTimeout;
      delete this.owlOption.responsive['600'].autoplayTimeout;
      delete this.owlOption.responsive['1000'].autoplayTimeout;
      delete this.owlOption.responsive['1300'].autoplayTimeout;
    }

    if (this.bannerResult[0]?.preview != undefined && this.bannerResult[0]?.preview.length > 0) {
      console.log('ngOnInit first check')
      let hrs = this.bannerResult[0]?.preview[0]?.preview_hrs ? parseInt(this.bannerResult[0]?.preview[0]?.preview_hrs) * 60 : 0;
      let min = this.bannerResult[0]?.preview[0]?.preview_min ? parseInt(this.bannerResult[0]?.preview[0]?.preview_min) * 60 : 0;
      let sec = this.bannerResult[0]?.preview[0]?.preview_sec ? this.bannerResult[0]?.preview[0]?.preview_sec : 0;
      this.timeDuration = (parseInt(sec) + min + hrs) * 1000;
      // console.log("total", this.timeDuration);
      this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, autoplay: false,smartSpeed: 1500 }
    } else {
      this.timeDuration = 5000;
      // console.log("total", this.timeDuration);
      this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, autoplay: true,smartSpeed: 1500 }
    }
    // this.timeDuration = 17000;

    //this.owlOption.autoplayTimeout = this.timeDuration;

    setTimeout(() => {
      const className = document.querySelectorAll('.banner_slider .owl-item');
      // console.log("allClassCount", className.length);
      for (let i = 0; i < className.length; i++) {
        if (className[i].classList.contains('cloned') == true) {
          className[i].querySelector('.trailer_video .video-js').removeAttribute('id');
          const gId = className[i].querySelector('.trailer_video .video-js');
          //// console.log("class exist", gId);
        }
      }

      const leftArrowClass = document.querySelector('.banner_slider .owl-nav');
      leftArrowClass.addEventListener('click', (evt) => this.playWithNextAndPre(evt));

      const clickOnOwlNav = document.querySelector('.banner_slider .owl-dots');
      clickOnOwlNav.addEventListener('click', (evt) => this.playWithNextAndPre(evt));
    }, 1000);

    setTimeout(() => {
      // if (this.bannerResult[0]?.preview != undefined && this.bannerResult[0]?.preview.length > 0) {
        this.playMoviePreview();
      // }
    }, 1500);



  }

  playWithNextAndPre(event) {
    console.log('playWithNextAndPre')
    setTimeout(() => {
      var classsName1 = document.querySelector('.banner_slider .active .trailer_video .video-js');
      var getActiveClassId = classsName1.getAttribute('id');
      var afterReplace = getActiveClassId.replace('bannervideo', '');
      
      if (videojs("bannervideo" + parseInt(afterReplace))) {
        this.showfewSecondImage[parseInt(afterReplace)] = false;
        videojs("bannervideo" + parseInt(afterReplace)).dispose();
      }

      if (this.bannerResult[afterReplace]?.preview != undefined && this.bannerResult[afterReplace]?.preview.length > 0) {
        this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, startPosition: afterReplace, autoplay: false, smartSpeed: 1500 }
      } else {
        this.owlOption = { ...this.owlOption, autoplayTimeout: 5000, startPosition: afterReplace, autoplay: true, smartSpeed: 1500 }
      }


      setTimeout(() => {
        const className = document.querySelectorAll('.banner_slider .owl-item');
        for (let i = 0; i < className.length; i++) {
          if (className[i].classList.contains('cloned') == true) {
            className[i].querySelector('.trailer_video .video-js').removeAttribute('id');
            const gId = className[i].querySelector('.trailer_video .video-js');
          } else {
            const ggId = className[i].querySelector('.trailer_video .video-js');
          }
        }
        setTimeout(() => {
          if (videojs("bannervideo" + this.currentPlayedIndex)) {
            this.showfewSecondImage[this.currentPlayedIndex] = false;
            videojs("bannervideo" + this.currentPlayedIndex).dispose();
          }

          this.playNextBannerVideo(parseInt(afterReplace));
        }, 1000);
      }, 1000);



      // console.log("currentPlayedIndex -::::::::::", this.currentPlayedIndex);
      // console.log("Acctive Index -::::::::::::", afterReplace);

    }, 1000)
  }

  playVideo(item, content, loginContent) {

    if (this.userInfo) {
      this.streamInfo = item;
      this.streamInfo['stop'] = this.streamInfo.stop ? this.streamInfo : 0;
      this.streamInfo['genre'] = this.streamInfo?.asset_detail?.genre;

      this.commonService.goToDetail(this.streamInfo, this.page_view);
      // this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title',windowClass: '_play_modal_jw jwfullscreen', size: 'xl', backdrop: 'static' }).result.then((result) => {
      // }, (reason) => {
      // });
    }
    else {
      this.commonService.setBeforeLoginState(item);
      this.modalService.open(loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }
  }

  AddToWatchListInBanner(e, item, loginContent) {

    // e.preventDefault();
    e.stopPropagation();

    if (this.userInfo && Object.keys(this.userInfo.result).length > 0) {
      this.watchListAddedItem = item;



      let userUnique = '';
      if (this.userInfo.result.register_by == "mobile") {
        userUnique = this.userInfo.result.mobile;
      } else {
        userUnique = this.userInfo.result.emailaddress;
      }

      this.googleEvent.eventEmitter("Watchlist", "Video_View", userUnique + '-' + item.code + '-' + item.name, 0, userUnique, userUnique + '-' + item.code + '-' + item.name);


      let addToplayListParams = {
        playlistid: this.playListId,
        catlogue: CatlougeTypeConstants.catlogue,
        plateform: "web",
        playingtype: "Video",
        assetId: item.asset_mongo_id ? item.asset_mongo_id : item._id,
        assetType: item.type,
        action: "saveAssetInPlaylist",
      }

      this.addedAssetIndex = this.data.findIndex(d => d['asset_mongo_id'] == item.asset_mongo_id)
      this.data[this.addedAssetIndex].is_watchlist_added = true;
      let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(addToplayListParams);

      if ((item.asset_mongo_id ? item.asset_mongo_id : item._id) != undefined) {
        this.playListService.addToPlayList(urlEncodedToplayListParams).subscribe((data) => {
          this.error_string = data['error_string'];
          this.error_code = data['error_code'];
          this.addToWatchListSuccessFromBanner.nativeElement.click();

          if (data.error_code == '200') {
            this.data[this.addedAssetIndex].is_watchlist_added = true;
            // this.itemAddedIntoWach.nativeElement.innerHTML = `Item Successfully added ${this.setPlayListItem?.name} into your Watchlist.`;  
            // document.getElementById('itemAddSuccessMsg').innerHTML= `Successfully added to Watchlist.`;  
          }

          if (data.error_code == '209') {
            this.data[this.addedAssetIndex].is_watchlist_added = false;
            // this.itemAddedIntoWach.nativeElement.innerHTML = 'This item already exist in your Watchlist ';
            // this.recommendedData.result[this.addedAssetIndexFromRecommended]['is_watchlist_added'] = true;
            this.deleteWatchListItem(item);
            // this.document.getElementById('itemAddSuccessMsg').innerHTML = `Already added to Watchlist.`;
          }


          setTimeout(() => {
            this.document.getElementById('closeAddToWatchListInBanner').click();
          }, 1500);

        });
      }


    }
    else {
      this.modalService.open(loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });
    }
  }

  addWatchList(loginContent) {

    if (this.userInfo) {
    }
    else {
      this.modalService.open(loginContent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      }, (reason) => {
      });

    }
  }

  deleteWatch(item) {
    this.deleteRecentWatch.emit(item);
  }

  deleteWatchListItem(item) {
    // console.log(item);

    this.deleteAsset_id = item.asset_mongo_id ? item.asset_mongo_id : item._id;

    let deletePlayListAssets = {
      playlistid: this.playListId,
      assetId: this.deleteAsset_id,
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      // playlist_asset_id:item._id,
      action: "deleteAssetFromPlaylist"
    }

    let urlEncodedToplayListParams = this.UserService.convertoJSONToUrlEncoded(deletePlayListAssets);
    this.playListService.deleteFromPlayList(urlEncodedToplayListParams).subscribe((data) => {
      // // console.log(data);
      // let index = this.playlist.findIndex(playItem => playItem._id === item._id);
    });

  }

  getWatchList() {

    this.playListId = localStorage.getItem('playListId');
    let temp = {
      playlistid: this.playListId ? this.playListId : '',
      catlogue: CatlougeTypeConstants.catlogue,
      plateform: "web",
      action: "getPlayListAsset",
    }

    let urlEncodedPlayListAssetsParams = this.UserService.convertoJSONToUrlEncoded(temp);
    // console.log(temp);
    this.playListService.getPlaylist(urlEncodedPlayListAssetsParams).subscribe(data => {
      // console.log(data);
      if (data && data.result && Array.isArray(data.result)) {
        if (Array.isArray(data.result)) {
          this.playListAvailable = true;
          this.playlist = data.result;
          // console.log(this.playlist);
          this.check_into_watchlist()
          return this.playlist;
        }
      }
    });

  }

  check_into_watchlist() {
    // console.log(this.playlist);
    this.data.forEach((tempAsset, tempIndex) => {

      if (this.playlist.findIndex(tempObj => tempObj._id == tempAsset.asset_mongo_id) != -1) {
        this.data[tempIndex].is_watchlist_added = true;
      } else {
        this.data[tempIndex].is_watchlist_added = false;
      }
    });

    // console.log(this.data);
  }


  playMoviePreview() {
    console.log('playMovie Preview')
    // for (let i = 0; i < this.bannerResult.length; i++) {
    this.showfewSecondImage[0] = false; // set banner image show some time status false
    this.currentPlayedIndex = 0;
    if (this.bannerResult[0]?.preview != undefined && this.bannerResult[0]?.preview.length > 0) {
      // let playObj =
      //   [{
      //     "cloudfront_key_pair_id": "K15HK487F5RBHJ",
      //     "cloudfront_policy": "eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vc3RhZ2UubmV4Z3R2LmNvbS92b2QvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTY5MjgwMjk3M30sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifX19XX0_",
      //     "cloudfront_signature": "BkbOWiLewII~K13fHhoZuy5TOozZcTn-WD0fpGdo5TfFGw8l2JUGRi~YaWYMYERjKFnXixXYKOGZi1qqeJc-5XmGWeRCjPlH6o5ZXOS6Dp9W3mAfn7g8xQx5253UAm89ZLd3b1bxoDnhLo8sOTt7fhO70dhLeisJ12nrajG~JvBjhisspRjWs5JrDVI5OdNvPVP~lbfFcsUVymqf-Ce4x05Ss2MNVzoICmP8ST3Hdgn7xRHTZjn-pY6I9Th0hUdRGHzEeWxeT48Aa5lnGxLYZP2xbrzvLkmm~qBLk-ZJOIJfLcH1neC1l3DhnonD5X36S7P3p0jm0Jf47bCzC1OsTQ__",
      //     // "cloud_url": "https://stage.nexgtv.com/vod/project/NEXGTV/digivive/event/STR15839046/preview/9c11ad6f-cc5c-466d-92ea-01100bac5308/V1/H264_TS/master.m3u8"
      //     "cloud_url": "./assets/img/banner-video1.mp4"
      //   },
      //   {
      //     "cloudfront_key_pair_id": "K15HK487F5RBHJ",
      //     "cloudfront_policy": "eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vc3RhZ2UubmV4Z3R2LmNvbS92b2QvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTY5MjgwMjk3M30sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifX19XX0_",
      //     "cloudfront_signature": "BkbOWiLewII~K13fHhoZuy5TOozZcTn-WD0fpGdo5TfFGw8l2JUGRi~YaWYMYERjKFnXixXYKOGZi1qqeJc-5XmGWeRCjPlH6o5ZXOS6Dp9W3mAfn7g8xQx5253UAm89ZLd3b1bxoDnhLo8sOTt7fhO70dhLeisJ12nrajG~JvBjhisspRjWs5JrDVI5OdNvPVP~lbfFcsUVymqf-Ce4x05Ss2MNVzoICmP8ST3Hdgn7xRHTZjn-pY6I9Th0hUdRGHzEeWxeT48Aa5lnGxLYZP2xbrzvLkmm~qBLk-ZJOIJfLcH1neC1l3DhnonD5X36S7P3p0jm0Jf47bCzC1OsTQ__",
      //     // "cloud_url": "https://stage.nexgtv.com/vod/project/NEXGTV/digivive/event/STR15839046/preview/9c11ad6f-cc5c-466d-92ea-01100bac5308/V1/H264_TS/master.m3u8"
      //     "cloud_url": "./assets/img/banner-video1.mp4"
      //   },
      //   {
      //     "cloudfront_key_pair_id": "K15HK487F5RBHJ",
      //     "cloudfront_policy": "eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vc3RhZ2UubmV4Z3R2LmNvbS92b2QvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTY5MjgwMjk3M30sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifX19XX0_",
      //     "cloudfront_signature": "BkbOWiLewII~K13fHhoZuy5TOozZcTn-WD0fpGdo5TfFGw8l2JUGRi~YaWYMYERjKFnXixXYKOGZi1qqeJc-5XmGWeRCjPlH6o5ZXOS6Dp9W3mAfn7g8xQx5253UAm89ZLd3b1bxoDnhLo8sOTt7fhO70dhLeisJ12nrajG~JvBjhisspRjWs5JrDVI5OdNvPVP~lbfFcsUVymqf-Ce4x05Ss2MNVzoICmP8ST3Hdgn7xRHTZjn-pY6I9Th0hUdRGHzEeWxeT48Aa5lnGxLYZP2xbrzvLkmm~qBLk-ZJOIJfLcH1neC1l3DhnonD5X36S7P3p0jm0Jf47bCzC1OsTQ__",
      //     // "cloud_url": "https://stage.nexgtv.com/vod/project/NEXGTV/digivive/event/STR15839046/preview/9c11ad6f-cc5c-466d-92ea-01100bac5308/V1/H264_TS/master.m3u8"
      //     "cloud_url": "./assets/img/banner-video2.mp4"
      //   }];

      let playObj = this.bannerResult[0].cloud_front;

      let videoPlayUrl: any;
      videoPlayUrl = playObj.cloud_url;
      //videoPlayUrl = playObj[0].cloud_url;
      this.setCookie(
        "CloudFront-Key-Pair-Id",
        playObj.cloudfront_key_pair_id.trim(),
        1
      );
      this.setCookie(
        "CloudFront-Policy",
        playObj.cloudfront_policy.trim(),
        1
      );
      this.setCookie(
        "CloudFront-Signature",
        playObj.cloudfront_signature.trim(),
        1
      );
      // application/x-mpegURL video/mp4

      ("http://j-cdr.digivive.com/HLS_Aaj/Aaj.m3u8");

      let new_source = {
        sources: [{ src: videoPlayUrl, type: "application/x-mpegURL" }],
        controls: true,
        autoplay: true,
        withCredentials: true,
        crossDomain: true,
        fluid: true,
        controlBar: {
          children: [
            "volumeMenuButton",
          ]
        },
      };

      this.playermodal[0] = videojs("bannervideo0", new_source);

      setTimeout(() => {
        this.showfewSecondImage[0] = true;
        this.playermodal[0].poster(this.bannerResult[0].image_cloudfront_url)
        this.playermodal[0].play();
      }, 2000);


      this.playermodal[0].on("ended", (event) => {
        console.log('0 video end', event)
        this.showfewSecondImage[0] = false; // for show current play video image status false 
        this.playermodal[0].pause();
        this.playermodal[0].dispose();
        this.is_muted = false;
        if (this.bannerResult[1].preview != undefined && this.bannerResult[1].preview.length > 0) {
          let hrs = this.bannerResult[1].preview[0].preview_hrs ? parseInt(this.bannerResult[1].preview[0].preview_hrs) * 60 : 0;
          let min = this.bannerResult[1].preview[0].preview_min ? parseInt(this.bannerResult[1].preview[0].preview_min) * 60 : 0;
          let sec = this.bannerResult[1].preview[0].preview_sec ? this.bannerResult[1].preview[0].preview_sec : 0;
          this.timeDuration = (parseInt(sec) + min + hrs) * 1000;
          this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, startPosition: 1, autoplay: false, smartSpeed: 1500 }
          // console.log("total", this.timeDuration);
        } else {
          this.timeDuration = 5000;
          this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, startPosition: 1, autoplay: true, smartSpeed: 1500 }
          // console.log("total", this.timeDuration);
        }
        setTimeout(() => {
          const className = document.querySelectorAll('.banner_slider .owl-item');
          // console.log("allClassCount", className.length);
          for (let i = 0; i < className.length; i++) {
            if (className[i].classList.contains('cloned') == true) {
              className[i].querySelector('.trailer_video .video-js').removeAttribute('id');
              const gId = className[i].querySelector('.trailer_video .video-js');
              // console.log("class exist", gId);
            } else {
              const ggId = className[i].querySelector('.trailer_video .video-js');
              // console.log("else class exist", ggId);
            }
          }
        }, 1000);

          this.playNextBannerVideo(1);
     
      });


      this.playermodal[0].muted(true);
    } else {
      this.checkSliderImageOrVideo(1);
    }
  }

  // this.playermodal.userActive(false);
  // var muted = this.playermodal.muted(true);

  // this.playermodal.on("error", (event) => {
  //   console.error(event);
  // });

  // }

  checkBannerTime() {
    this.owlOption.responsive['1300'].autoplayTimeout = this.timeDuration;
    // console.log("check banner  Time ", this.timeDuration);
    // console.log("check banner autoplay Time ", this.owlOption);
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }


  opacityValue: number = 1;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event: Event) {
    $('section.slider_img').css("opacity", 1 - $(window).scrollTop() / 500)
  }


  muteUnmutePreview(is_muted, index) {
    if (is_muted) {
      this.is_muted = false;
      var muted = this.playermodal[index].muted(false);
    } else {
      this.is_muted = true;
      var muted = this.playermodal[index].muted(true);
    }
    // console.log(muted);
  }


  playNextBannerVideo(index) {
    console.log('play next banner video =>', index)
    this.currentPlayedIndex = index;
    if (this.bannerResult[index].preview != undefined && this.bannerResult[index].preview.length > 0) {
      setTimeout(() => {
        this.showfewSecondImage[index] = true;
        // let playObj =
        //   [{
        //     "cloudfront_key_pair_id": "K15HK487F5RBHJ",
        //     "cloudfront_policy": "eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vc3RhZ2UubmV4Z3R2LmNvbS92b2QvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTY5MjgwMjk3M30sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifX19XX0_",
        //     "cloudfront_signature": "BkbOWiLewII~K13fHhoZuy5TOozZcTn-WD0fpGdo5TfFGw8l2JUGRi~YaWYMYERjKFnXixXYKOGZi1qqeJc-5XmGWeRCjPlH6o5ZXOS6Dp9W3mAfn7g8xQx5253UAm89ZLd3b1bxoDnhLo8sOTt7fhO70dhLeisJ12nrajG~JvBjhisspRjWs5JrDVI5OdNvPVP~lbfFcsUVymqf-Ce4x05Ss2MNVzoICmP8ST3Hdgn7xRHTZjn-pY6I9Th0hUdRGHzEeWxeT48Aa5lnGxLYZP2xbrzvLkmm~qBLk-ZJOIJfLcH1neC1l3DhnonD5X36S7P3p0jm0Jf47bCzC1OsTQ__",
        //     // "cloud_url": "https://stage.nexgtv.com/vod/project/NEXGTV/digivive/event/STR15839046/preview/9c11ad6f-cc5c-466d-92ea-01100bac5308/V1/H264_TS/master.m3u8"
        //     "cloud_url": "./assets/img/banner-video.mp4"
        //   },
        //   {
        //     "cloudfront_key_pair_id": "K15HK487F5RBHJ",
        //     "cloudfront_policy": "eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vc3RhZ2UubmV4Z3R2LmNvbS92b2QvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTY5MjgwMjk3M30sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifX19XX0_",
        //     "cloudfront_signature": "BkbOWiLewII~K13fHhoZuy5TOozZcTn-WD0fpGdo5TfFGw8l2JUGRi~YaWYMYERjKFnXixXYKOGZi1qqeJc-5XmGWeRCjPlH6o5ZXOS6Dp9W3mAfn7g8xQx5253UAm89ZLd3b1bxoDnhLo8sOTt7fhO70dhLeisJ12nrajG~JvBjhisspRjWs5JrDVI5OdNvPVP~lbfFcsUVymqf-Ce4x05Ss2MNVzoICmP8ST3Hdgn7xRHTZjn-pY6I9Th0hUdRGHzEeWxeT48Aa5lnGxLYZP2xbrzvLkmm~qBLk-ZJOIJfLcH1neC1l3DhnonD5X36S7P3p0jm0Jf47bCzC1OsTQ__",
        //     // "cloud_url": "https://stage.nexgtv.com/vod/project/NEXGTV/digivive/event/STR15839046/preview/9c11ad6f-cc5c-466d-92ea-01100bac5308/V1/H264_TS/master.m3u8"
        //     "cloud_url": "./assets/img/banner-video1.mp4"
        //   },
        //   {
        //     "cloudfront_key_pair_id": "K15HK487F5RBHJ",
        //     "cloudfront_policy": "eyJTdGF0ZW1lbnQiOiBbeyJSZXNvdXJjZSI6Imh0dHBzOi8vc3RhZ2UubmV4Z3R2LmNvbS92b2QvKiIsIkNvbmRpdGlvbiI6eyJEYXRlTGVzc1RoYW4iOnsiQVdTOkVwb2NoVGltZSI6MTY5MjgwMjk3M30sIklwQWRkcmVzcyI6eyJBV1M6U291cmNlSXAiOiIwLjAuMC4wLzAifX19XX0_",
        //     "cloudfront_signature": "BkbOWiLewII~K13fHhoZuy5TOozZcTn-WD0fpGdo5TfFGw8l2JUGRi~YaWYMYERjKFnXixXYKOGZi1qqeJc-5XmGWeRCjPlH6o5ZXOS6Dp9W3mAfn7g8xQx5253UAm89ZLd3b1bxoDnhLo8sOTt7fhO70dhLeisJ12nrajG~JvBjhisspRjWs5JrDVI5OdNvPVP~lbfFcsUVymqf-Ce4x05Ss2MNVzoICmP8ST3Hdgn7xRHTZjn-pY6I9Th0hUdRGHzEeWxeT48Aa5lnGxLYZP2xbrzvLkmm~qBLk-ZJOIJfLcH1neC1l3DhnonD5X36S7P3p0jm0Jf47bCzC1OsTQ__",
        //     // "cloud_url": "https://stage.nexgtv.com/vod/project/NEXGTV/digivive/event/STR15839046/preview/9c11ad6f-cc5c-466d-92ea-01100bac5308/V1/H264_TS/master.m3u8"
        //     "cloud_url": "./assets/img/banner-video.mp4"
        //   }];
        let playObj = this.bannerResult[index].cloud_front;

        let videoPlayUrl: any;
        videoPlayUrl = playObj.cloud_url;
        this.setCookie(
          "CloudFront-Key-Pair-Id",
          playObj.cloudfront_key_pair_id.trim(),
          1
        );
        this.setCookie(
          "CloudFront-Policy",
          playObj.cloudfront_policy.trim(),
          1
        );
        this.setCookie(
          "CloudFront-Signature",
          playObj.cloudfront_signature.trim(),
          1
        );
        ("http://j-cdr.digivive.com/HLS_Aaj/Aaj.m3u8");
        //video/mp4 application/x-mpegURL

        let new_source = {
          sources: [{ src: videoPlayUrl, type: "application/x-mpegURL" }],
          controls: true,
          autoplay: true,
          withCredentials: true,
          crossDomain: true,
          fluid: true,
          controlBar: {
            children: [
              "volumeMenuButton",
            ]
          },
        };



        this.playermodal[index] = videojs("bannervideo" + index, new_source);
        this.bannerResult[index].image_cloudfront_url;
        this.is_muted = true;
        this.playermodal[index].poster(this.bannerResult[index].image_cloudfront_url);
        this.playermodal[index].play();
        this.playermodal[index].muted(true);
        this.playermodal[index].on("ended", (event) => {
          console.log('video end =>', index);
          console.log('video end  event', event)
          this.showfewSecondImage[index] = false; // for show current play video image status false 
          this.playermodal[index].pause();

          if (index < (this.bannerResult.length - 1), index) {
            if (this.bannerResult[index].preview != undefined && this.bannerResult[index].preview.length > 0) {
              let hrs = this.bannerResult[index + 1]?.preview[0]?.preview_hrs ? parseInt(this.bannerResult[index + 1]?.preview[0]?.preview_hrs) * 60 : 0;
              let min = this.bannerResult[index + 1]?.preview[0]?.preview_min ? parseInt(this.bannerResult[index + 1]?.preview[0]?.preview_min) * 60 : 0;
              let sec = this.bannerResult[index + 1]?.preview[0]?.preview_sec ? this.bannerResult[index + 1]?.preview[0]?.preview_sec : 0;
              this.timeDuration = (parseInt(sec) + min + hrs) * 1000;
              // console.log("total ef", index, this.timeDuration);
            } else {
              this.timeDuration = 5000;
              // console.log("total", index, this.timeDuration);
            }

          } else {
            if (this.bannerResult[0].preview != undefined && this.bannerResult[0].preview.length > 0) {
              let hrs = this.bannerResult[0]?.preview[0]?.preview_hrs ? parseInt(this.bannerResult[0]?.preview[0]?.preview_hrs) * 60 : 0;
              let min = this.bannerResult[0]?.preview[0]?.preview_min ? parseInt(this.bannerResult[0]?.preview[0]?.preview_min) * 60 : 0;
              let sec = this.bannerResult[0]?.preview[0]?.preview_sec ? this.bannerResult[0]?.preview[0]?.preview_sec : 0;
              this.timeDuration = (parseInt(sec) + min + hrs) * 1000;
              // console.log("total 0 if ", index, this.timeDuration);
            } else {
              this.timeDuration = 5000;
              // console.log("total 0 else ", index, this.timeDuration);
            }
          }

          //this.timeDuration = 17000;
          this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, startPosition: index + 1, autoplay: false, smartSpeed: 1500 }
          //  this.owlOption.autoplayTimeout = this.timeDuration;
          //this.owlOption.autoplayTimeout = this.timeDuration;

          setTimeout(() => {
            const className = document.querySelectorAll('.banner_slider .owl-item');
            // console.log("allClassCount", className.length);
            for (let i = 0; i < className.length; i++) {
              if (className[i].classList.contains('cloned') == true) {
                className[i].querySelector('.trailer_video .video-js').removeAttribute('id');
                const gId = className[i].querySelector('.trailer_video .video-js');
                // // console.log("class exist", gId);
              } else {
                const ggId = className[i].querySelector('.trailer_video .video-js');
                // // console.log("else class exist", ggId);
              }
            }
          }, 1000);

          if (index < (this.bannerResult.length - 1)) {
            console.log('call play next video with index', index + 1)
            this.is_muted = true;
            this.showfewSecondImage[index] = false;
            this.playermodal[index].dispose();
            this.playNextBannerVideo(index + 1);
          } else {
            this.is_muted = true;
            this.showfewSecondImage[index] = false;
            this.playermodal[index].dispose();
            this.playNextBannerVideo(0);
          }
        });

        // // console.log("next banner video this.owlOption -:", this.owlOption);
      }, 1000);
    } else {
      if (parseInt(index) < (this.bannerResult.length - 1)) {
        this.showfewSecondImage[index + 1] = false;
        this.checkSliderImageOrVideo(index + 1);
      } else {
        this.showfewSecondImage[0] = false;
        this.checkSliderImageOrVideo(0);
      }
    }

  }


  checkSliderImageOrVideo(index) {
    console.log("check optionss in check slider on top", this.owlOption);
    setTimeout(() => {
      if (this.bannerResult[index].preview != undefined && this.bannerResult[index].preview.length > 0) {
        let hrs = this.bannerResult[index].preview[0].preview_hrs ? parseInt(this.bannerResult[index].preview[0].preview_hrs) * 60 : 0;
        let min = this.bannerResult[index].preview[0].preview_min ? parseInt(this.bannerResult[index].preview[0].preview_min) * 60 : 0;
        let sec = this.bannerResult[index].preview[0].preview_sec ? this.bannerResult[index].preview[0].preview_sec : 0;
        this.timeDuration = (parseInt(sec) + min + hrs) * 1000;
        this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, startPosition: index, autoplay: false, smartSpeed: 1500 }
      } else {
        this.timeDuration = 5000;
        this.owlOption = { ...this.owlOption, autoplayTimeout: this.timeDuration, startPosition: index, autoplay: true, smartSpeed: 1500 }
      }

      
            

      setTimeout(() => {
        const className = document.querySelectorAll('.banner_slider .owl-item');
        for (let i = 0; i < className.length; i++) {
          if (className[i].classList.contains('cloned') == true) {
            className[i].querySelector('.trailer_video .video-js').removeAttribute('id');
            const gId = className[i].querySelector('.trailer_video .video-js');
          } else {
            const ggId = className[i].querySelector('.trailer_video .video-js');
          }
        }
        console.log("check ortion after 5 second in check slider function ", this.owlOption);
        // setTimeout(()=>{
        this.playNextBannerVideo(index);
        // },1000);
      }, 1000);
    }, 4000);
  }

}