import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  public isRedeemPage: boolean = false;
  isLegalPage : boolean = false;
  constructor(private route: ActivatedRoute,private router: Router,) { }

  ngOnInit(): void {
    if ((this.router.url).includes('/redeem')) {
      if (navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/Android/i)) {
        this.isRedeemPage = true;
      }
    }

    if ((this.router.url).includes('/legal')) {
      this.isLegalPage = true;
    }

  }

  flipLogoPlay(){
    console.log('Logo')
  }

  flipLogoApp(){

  }

}
