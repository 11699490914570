import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PageTitleService } from 'src/app/services/title/page-title.service';
import { EventTrackingService } from 'src/app/services/google/event-tracking.service';
import { UserService } from 'src/app/core/services/user.service';
import { environment } from 'src/environments/environment.prod';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  private owlSliderOptionSource = new Subject<any>();

  owlSliderOptionActivity$ = this.owlSliderOptionSource.asObservable();

  owlSliderOptionActivity(owlOption: any) {
    console.log('annoucing owlSliderOption');
    this.owlSliderOptionSource.next(owlOption);
  }

  fileterDataType = localStorage.getItem('filterTypeData');
  isCloudfrontEnabled = JSON.parse(this.fileterDataType).is_cloudfront_enabled;
  isbucket = JSON.parse(this.fileterDataType).bucket;

  detailPageDataArray: any = [];
  assetsImage: string = '';
  BASE_ASSETS_PATH: string = environment.BASE_ASSETS_PATH;// 'https://res.cloudinary.com/digivive/image/upload/';
  APP_CLOUD_NAME: string = environment.APP_CLOUD_NAME;
  EXT: string = environment.SRC_EXT;
  filterType: any = this.isCloudfrontEnabled;
  filterTypeBucket: any = this.isbucket;
  objectOfAllStrings: any;
  

  assetsData: any = [];
  isCpampignBanner: boolean = false;

  constructor(
    private apiService: ApiService,
    public router: Router,
    public pageTitleService: PageTitleService,
    private googleEvent: EventTrackingService,
    private userService: UserService
  ) { }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  numberWithAstrick(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 13 || charCode == 42 || (charCode >= 48 && charCode <= 57)) {
      return true;
    }
    return false;
  }

  charOnly(e) { // handel space in any input at fist possition
    if (e.which === 32 && !e.target.value.length)
      e.preventDefault();
  }

  alphabetOnly(e) {
    if (e.charCode == 13 || e.charCode == 32 || (e.charCode > 64 && e.charCode < 91) || (e.charCode > 96 && e.charCode < 123)) {
      return true;
    } else {
      return false;
    }
  }

  alphabetWithNumber(e) {
    if ((e.charCode > 31 && e.charCode < 48) || (e.charCode > 57 && e.charCode < 65) || (e.charCode > 90 && e.charCode < 97) || (e.charCode > 122 && e.charCode <= 126)) {
      return false;
    } else {
      return true;
    }
  }

  alphabetWithSplChrOnly(e) {
    if (e.charCode < 48 && e.charCode > 58) {
      return false;
    } else {
      return true;
    }
  }

  ipOnly(e) {
    if ((e.charCode > 64 && e.charCode < 71) || (e.charCode > 96 && e.charCode < 103) || e.charCode == 8 || e.charCode == 46 || (e.charCode >= 48 && e.charCode <= 58)) {
      return true;
    } else {
      return false;
    }
  }


  getTimezone() {
    // const url = `common/getTimeZone`;
    // return this.apiService.get(url).pipe(map(data => {
    //   return data;
    // }));
  }


  reloadPage() {
    window.location.reload();
  }

  floatOnly(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var number = evt.target.value.split('.');
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      return false;
    }
    //get the carat position
    //var caratPos = getSelectionStart(el);
    var dotPos = evt.target.value.indexOf(".");
    if (dotPos > -1 && (number[1].length > 4)) {
      return false;
    }
    return true;
  }

  mobileLimit(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    let inputValue = evt.target.value;
    if (inputValue.length == 10 || charCode == 101) {
      return false;
    }
    return true;

  }

  avoidSpace(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (evt.target.value == '') {
      return true;
    }
    // if(charCode == 32 || charCode == 62){
    //   return false;
    // }
    return true;
  }

  otpLimit(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    let inputValue = evt.target.value;
    if (inputValue.length == 6 || charCode == 101) {
      return false;
    }
    return true;
  }

  onlyPositiveNumber(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode == 45 || charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  decimalWIth2digits(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var number = evt.target.value.split('.');
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      return false;
    }
    //get the carat position
    //var caratPos = getSelectionStart(el);
    var dotPos = evt.target.value.indexOf(".");
    if (dotPos > -1 && (number[1].length > 1)) {
      return false;
    }
    return true;

  }
  floatWithNegativeNumber(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    var number = evt.target.value.split('.');
    var number1 = evt.target.value.split('-');
    if (charCode != 45 && charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    //just one dot
    if (number.length > 1 && charCode == 46) {
      return false;
    }

    if (number1.length > 1 && charCode == 45) {
      return false;
    }
    //get the carat position
    //var caratPos = getSelectionStart(el);
    var dotPos = evt.target.value.indexOf(".");
    if (dotPos > -1 && (number[1].length > 4)) {
      return false;
    }
    return true;
  }

  saveDetailPageData(key, data) {
    this.detailPageDataArray[key] = data;
  }
  get detailPageData() {
    return this.detailPageDataArray;
  }

  goToDetail(data, page?: string, reload?: number) {
    let userinfo = JSON.parse(localStorage.getItem('userInfo'));

    let userUnique = 'NA';
    if (Object.keys(userinfo.result).length > 0) {
      if (userinfo.result.register_by == 'mobile') {
        userUnique = userinfo.result.mobile;
      } else {
        userUnique = userinfo.result.emailaddress;
      }
    }
    console.log(page + ' page');
    let title = data.name == undefined ? data.asset_detail.name : data.name;
    if (page != undefined)
    //this.googleEvent.eventEmitter(page, "Video_View", userUnique + '-' + data.code + '-' + title);
    this.googleEvent.eventEmitter(page, "Video_View", userUnique + '-' + data.code + '-' + title, 0, userUnique, userUnique + '-' + data.code + '-' + title);



    let slug = this.detailRoute(data);

    this.saveDetailPageData(data.code, data);

    if (reload != undefined)
      window.location.href = window.location.origin + '/' + slug;

    this.router.navigate([slug])
  }

  detailRoute(data) {
    if(data.type=='episode'){
      data.type = 'tvshow';
    }
    
    if(data.type=='live'){
      data.type = 'livetv';
    }

    let title = data.name == undefined ? data.asset_detail.name : data.name;
    let slug = this.strToSlug(title);
    return 'detail/' + slug + '/' + data.code + '/' + data.type;
  }

  strToSlug(title) {

    return title.toString().toLowerCase()
      .replace(/\s+/g, '-')           // Replace spaces with -
      .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
      .replace(/\-\-+/g, '-')         // Replace multiple - with single -
      .replace(/^-+/, '')             // Trim - from start of text
      .replace(/-+$/, '');
  }

  setBeforeLoginState(data) {
    this.assetsData = data;
  }
  setBeforeLoginState2() {
    this.isCpampignBanner = true;
  }

  getBeforeLoginState() {
    return this.isCpampignBanner;
   }

  getAssetsData() {
    return this.assetsData;
  }

  activeHeaderOnAssetClick(data) {
  }

  stdNumber(e) {
    var input = e.target;
    input.onkeypress = function (e) {
      e = e || window.event;
      var charCode = (typeof e.which == "number") ? e.which : e.keyCode;
      // Allow non-printable keys
      if (!charCode || charCode == 8 /* Backspace */) {
        return;
      }

      var typedChar = String.fromCharCode(charCode);

      // Allow numeric characters
      if (/\d/.test(typedChar)) {
        return;
      }

      // Allow the minus sign (-) if the user enters it first
      if (typedChar == "+" && this.value == "") {
        return;
      }

      // In all other cases, suppress the event
      return false;
    };
  }

  convertDate(inputFormat) {
    function pad(s) { return (s < 10) ? '0' + s : s; }
    var d = new Date(inputFormat)
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/')
  }


  passwordMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors.mustMatch) {
        // return if another validator has already found an error on the matchingControl
        return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
        matchingControl.setErrors({ mustMatch: true });
      } else {
        matchingControl.setErrors(null);
      }
    }
  }

  pad(d) {
    let tempD = parseInt(d);
    if (tempD > 0) {
      return tempD;
    } else {
      return '';
    }
    // return (d < 10) ? d.substr(1, 1) : d.toString();
  }

  compressImgUrl2(img: string, params: any = {}, notFound:string) {
    if (img == undefined || img == null || img == "") {
      this.assetsImage = "./assets/img/not-found/"+notFound+".png";
      return this.assetsImage;
    } else {

      if (this.filterType == 1) {
        let ext = img.split(".");
        let extention = ext[1];
        let breckFordomin = img.split('/', 3);
        let domianName = breckFordomin.toString().replace(',,', '//');
     
        let index = img.lastIndexOf("/") + 2;
        let filename = img.substr(index);
        var url = img;
        var pathname = new URL(url).pathname;
        let key1 = pathname;
        if(key1.match('//')){
          var key = key1.substring(2);
        }else{
          var key = key1.substring(1);
        }
        let WithOutImageName = img.replace(filename, '');
        let bucketName = this.filterTypeBucket;


        if (Object.keys(params).length == 0 || params == undefined) {
          return this.assetsImage = WithOutImageName + domianName + '/' + filename;
        }


        if (params.crop == "scale") {
          this.objectOfAllStrings = {
            "bucket": bucketName,
            "key": key,
            "edits": {
              "normalize": false,
              "grayscale": false,
              "webp": true,
              "sharpen": false,
              "resize": {
                "width": params.width,
                "height": params.height,
                "fit": "cover"
              }
            },
            "smartCrop": {
              "padding": 40
            }
          }
        } else {
          this.objectOfAllStrings = {
            "bucket": bucketName,
            "key": key,
            "edits": {
              "normalize": false,
              "grayscale": false,
              "webp": true,
              "sharpen": false,
              "resize": {
                "width": params.width,
                "height": params.height,
                "fit": params.imgType == "square" || params.imgType == 'portrait' ? "fill" :"cover"
              }
            }
          }
        }

        let encodeBase64 = btoa(JSON.stringify(this.objectOfAllStrings));
        var fullUrl = domianName + "/" + encodeBase64;
        return this.assetsImage = fullUrl;

      } else {
        let splitUrl: any = img.split("upload");
        let BASE_ASSETS_PATH = splitUrl[0] + 'upload/';
        let ext = splitUrl[1] ? splitUrl[1].split(".") : '';
        let extention = ext[1];
        let defaultParms = 'f_auto,q_auto,';
        let index = img.lastIndexOf("/") + 1;
        let filename = img.substr(index);

        let a = filename.split(".");
        let assetname = filename.substr(0, filename.lastIndexOf("."));
        if (extention == undefined || extention == null || extention == "") {
          this.assetsImage = "./assets/img/not-found/landscape.png";
          return this.assetsImage;
        }


        if (Object.keys(params).length == 0 || params == undefined) {
          //this.assetsImage = img;
          if (a.length <= 1) {
            this.assetsImage = BASE_ASSETS_PATH + defaultParms + '/' + this.APP_CLOUD_NAME + '/' + a[0] + this.EXT;
            return this.assetsImage;
          }
          else {
            this.assetsImage = BASE_ASSETS_PATH + defaultParms + '/' + this.APP_CLOUD_NAME + '/' + assetname + this.EXT;
            return this.assetsImage;
          }

        } else {

          let resizeAlog = '';

          if (params.width != undefined) {
            resizeAlog = resizeAlog + 'w_' + params.width + ',';
          }

          if (params.height != undefined) {
            resizeAlog = resizeAlog + 'h_' + params.height + ',';
          }
          // croping modes =>  https://cloudinary.com/documentation/image_transformations#resizing_and_cropping_images
          if (params.crop != undefined) {
            resizeAlog = resizeAlog + 'c_' + params.crop + ',';
          }
          // radius doc => https://cloudinary.com/documentation/image_transformations#rounding_corners_and_creating_circular_images
          if (params.radius != undefined) {
            resizeAlog = resizeAlog + 'r_' + params.radius + ',';
          }
          // auto, face, faces, eyes, center
          if (params.gravity != undefined) {
            resizeAlog = resizeAlog + 'g_' + params.gravity + ',';
          }

          //effect modes => https://cloudinary.com/documentation/image_transformations#applying_image_effects_and_filters
          if (params.effect != undefined) {
            resizeAlog = resizeAlog + 'e_' + params.effect + ',';
          }

          resizeAlog = resizeAlog.trim();
          if (resizeAlog == '') {
            //this.assetsImage = img;
            this.assetsImage = BASE_ASSETS_PATH + defaultParms + '/' + this.APP_CLOUD_NAME + '/' + assetname + this.EXT;
            return this.assetsImage;
          } else {
            resizeAlog = resizeAlog.substr(0, (resizeAlog.length - 1));
          }



          this.assetsImage = BASE_ASSETS_PATH + defaultParms + resizeAlog + '/' + this.APP_CLOUD_NAME + '/' + assetname + this.EXT;
          return this.assetsImage;

        }

      }

    }
  }


}
